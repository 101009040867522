import { linkBlock } from "./fragments.gql"

export const colourFragment = `
  hex
  red
  green
  blue
`

export default () => `query {
  allGlobalPromos(filter: {
    enabled: {
      eq: true
    }
  }) {
    id
    shopifyPromotionId
    productTagText
    bannerMessage
    mobileBannerMessage
    bannerLink {
      ${linkBlock}
    }
    theme {
      id
      type: themeType
      bgTintColour {
        ${colourFragment}
      }
      bgColour {
        ${colourFragment}
      }
      midBgColour {
        ${colourFragment}
      }
      keyColour {
        ${colourFragment}
      }
      highlightColour {
        ${colourFragment}
      }
      shineColour {
        ${colourFragment}
      }
    }
  }
}`
