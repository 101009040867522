import dialogQuery from '~/gql/dialog.gql.js'

export const useModalStore = defineStore('modal', {
  state: () => ({
    open: false,
    slug: null,
    content: {},
    params: {}
  }),
  
  actions: {
    OPEN_MODAL ({ slug = null, params = {} }) {
      this.open = true
      this.slug = slug
      this.params = params
    },
    CLOSE_MODAL () {
      this.open = false
      this.slug = null
      this.params = {}
    },
    SET_MODAL_STATE (open) {
      this.open = open
    },
    STORE_CONTENT ({ slug, content }) {
      if (!content) return
      this.content[slug] = content
    },
    OPEN_MODAL_PAGE ({ slug, params = {} }) {
      if (process.browser) {
        const params = new URLSearchParams(window.location.search)
        params.set('modal', slug)
  
        const queryString = new URLSearchParams(params).toString()
        window.history.pushState({}, '', queryString ? `?${queryString}` : window.location.pathname)
      }
  
      this.OPEN_MODAL({ slug, params })
    },
    CLOSE_MODAL_PAGE () {
      if (process.browser) {
        const params = new URLSearchParams(window.location.search)
        params.delete('modal')
  
        const queryString = new URLSearchParams(params).toString()
        window.history.pushState({}, '', queryString ? `?${queryString}` : window.location.pathname)
      }
      this.CLOSE_MODAL()
    },
    async PREFETCH_CONTENT (slug) {
      const { data: response } = await useDatoQuery({
        query: dialogQuery,
        variables: {
          slug: slug.replace('/', '__')
        }
      })

      this.STORE_CONTENT({ slug, content: response.value.dialog })
    }
  },
  
  
  getters: {
    openModalMetaTags: (state) => state.content[this.slug]?.meta,
    getPrefetchedContent: (state) => {
      return (slug) => state.content[slug]
    }
  }
})
