
import { RudderAnalytics } from '@rudderstack/analytics-js'

import buildProductEvents from "./track/product"
import buildCartEvents from "./track/cart"
import buildCheckoutEvents from "./track/checkout"
import buildCouponEvents from "./track/coupon"
import buildReferralEvents from "./track/referral"
import buildExperimentEvents from "./track/experiment"
import buildExploreEvents from "./track/explore"
import buildLensGuideEvents from "./track/lens-guide"
import buildAttributionEvents from "./track/attribution"
import buildNavigationEvents from "./track/navigation"
import buildVTOEvents from "./track/vto"
import buildMultiStepEvents from "./track/multi-step"
import buildGlobalEvents from "./track/global"
import buildFormEvents from "./track/form"
import buildFilterEvents from "./track/filters"
import buildEmailSignupEvents from "./track/email-signup"
import buildContentEvents from "./track/content"
import buildWelcomeEvents from "./track/welcome"
import buildSupportEvents from "./track/support"
import buildCompetitionEvents from "./track/competition"
import buildInvestEvents from "./track/invest"
import buildConvincerEvents from "./track/convincers"
import buildGiftSelectorEvents from "./track/gift-selector"

import getFBQ from "./track/fbq"
import pinterest from "./track/pinterest"
import reddit from "./track/reddit"
import * as amplitude from '@amplitude/analytics-browser'

const rudderanalytics = new RudderAnalytics()

export default defineNuxtPlugin((nuxtApp) => {
  try {
    const route = useRoute()
    const router = useRouter()
    const userStore = useUserStore()
    const runtimeConfig = useRuntimeConfig()

    try {
      rudderanalytics.load(
        runtimeConfig.public.rudderstackWriteKey,
        runtimeConfig.public.rudderstackDataPlaneUrl,
        {
          integrations: { All: true },
        }
      )
    } catch (error) {
      // This isn't a critical error that should break the site.
      // TODO: Ensure this is tracked by Sentry
      const { $sentry } = nuxtApp
      if ($sentry) {
        $sentry.captureException(error)
      } else {
        console.error(error)
      }
    }

    amplitude.init(runtimeConfig.public.amplitude.apiKey, {
      defaultTracking: {
        attribution: true,
        pageViews: false,
        sessions: true,
        formInteractions: true,
        fileDownloads: true
      }
    })
    
    const fbq = getFBQ(nuxtApp, rudderanalytics?.getAnonymousId())

    router.afterEach(() => {
      const unlocalisedPath = route.meta.unlocalisedPath ||
        window.location.pathname.replace(/^\/[a-z]+-([a-z]+)\//, '/') // strip out the locale from location path
      const locale = route.params.locale || 'en-gb'
      const localeCountry = locale.split('-')[1].toUpperCase()
      const cfCountry = window?.visitorCountryCode
      let isMismatchedCountry = null
      if (cfCountry) isMismatchedCountry = cfCountry !== localeCountry
        
      rudderanalytics.page(null, {
        pageType: route.meta.pageType,
        unlocalisedPath
      })

      amplitude?.track(`Viewed ${route.meta.pageType || route.name}`, {
        pagePath: route.path,
        pageFullPath: route.fullPath,
        pageName: route.name,
        pageType: route.meta.pageType,
        locale,
        localeCountry,
        cfCountry,
        isMismatchedCountry,
        unlocalisedPath
      })

      fbq?.track("PageView")
      pinterest?.pageVisit()
      reddit.pageVisit()
    })

    let addedLogging = false
    rudderanalytics.ready(() => {
      if (addedLogging) return
      const track = rudderanalytics.track

      rudderanalytics.track = (event, properties) => {
        properties = {
          ...properties,
          pagePath: route.path,
          pageFullPath: route.fullPath,
          pageName: route.name,
          pageType: route.meta.pageType
        }

        // console.log('rudderanalytics.track', event, properties)

        // Send event directly to Amplitude
        amplitude.track(event, properties)

        return new Promise((resolve) => {
          track(event, properties, resolve)
        })
      }

      // Add experiment tracking as user properties
      const abTestStore = useAbTestStore()
      const tests = abTestStore.tests
      const identifyEvent = new amplitude.Identify()
      for (const key in tests) {
        identifyEvent.set(`experiment-${key}`, tests[key].value)
      }
      amplitude.identify(identifyEvent)

      addedLogging = true
    })

    rudderanalytics.product = buildProductEvents(rudderanalytics, fbq)
    rudderanalytics.cart = buildCartEvents(rudderanalytics)
    rudderanalytics.checkout = buildCheckoutEvents(rudderanalytics, fbq)
    rudderanalytics.coupon = buildCouponEvents(rudderanalytics)
    rudderanalytics.referral = buildReferralEvents(rudderanalytics)
    rudderanalytics.experiment = buildExperimentEvents(rudderanalytics)
    rudderanalytics.explore = buildExploreEvents(rudderanalytics)
    rudderanalytics.lensGuide = buildLensGuideEvents(rudderanalytics)
    rudderanalytics.attribution = buildAttributionEvents(rudderanalytics)
    rudderanalytics.navigation = buildNavigationEvents(rudderanalytics)
    rudderanalytics.vto = buildVTOEvents(rudderanalytics)
    rudderanalytics.multiStep = buildMultiStepEvents(rudderanalytics)
    rudderanalytics.global = buildGlobalEvents(rudderanalytics)
    rudderanalytics.form = buildFormEvents(rudderanalytics, fbq)
    rudderanalytics.filter = buildFilterEvents(rudderanalytics)
    rudderanalytics.emailSignup = buildEmailSignupEvents(rudderanalytics)
    rudderanalytics.content = buildContentEvents(rudderanalytics)
    rudderanalytics.welcome = buildWelcomeEvents(rudderanalytics)
    rudderanalytics.support = buildSupportEvents(rudderanalytics)
    rudderanalytics.competition = buildCompetitionEvents(rudderanalytics)
    rudderanalytics.invest = buildInvestEvents(rudderanalytics)
    rudderanalytics.convincers = buildConvincerEvents(rudderanalytics)
    rudderanalytics.giftSelector = buildGiftSelectorEvents(rudderanalytics)

    rudderanalytics.updateUserData = () => {
      fbq.setUserDataFromContext(rudderanalytics.getAnonymousId())
      fbq.init()

      if (userStore.email) {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('user_id', userStore.email)
        amplitude.identify(identifyEvent)
      }
    }

    // You can alternatively use this format, which comes with automatic type support
    return {
      provide: {
        track: rudderanalytics,
        fbq: fbq,
        amplitude
      },
    }
  } catch (error) {
    // This isn't a critical error that should break the site.
    const { $sentry } = nuxtApp
    if ($sentry) {
      $sentry.captureException(error)
    } else {
      console.error(error)
    }

    // Create a dummy track object to prevent errors
    const proxy = new Proxy({}, {
      get: () => (...args) => {
        console.error(...args)
      }
    })
    const track = new Proxy(proxy, {
      get: (_, prop) => {
        if (prop === 'identify') {
          return () => {
            console.error("Tracking not available: identify")
          }
        }
        console.error("Tracking not available:", prop)
        return proxy
      }
    })

    return {
      provide: {
        track,
        fbq: proxy,
        amplitude
      }
    }
  }
})
