import { reactive, createApp } from 'vue'
import { PartOption, Upsell } from './'
import { getSkuWithoutLensTech } from '~/assets/js/helpers/product'
import allLayerTemplates, { hasLayerTemplate } from '~/assets/360/all.mjs'
import {
  TAG_EYEGLASSES,
  TAG_PRESCRIPTION,
} from '~/assets/js/constants/commerceTags'

const PRODUCT_FIELDS = [
  'id',
  'commerceId',
  'sku',
  'name',
  'basePrice',
  'basePriceGBP',
  'doNotTrackStock',
  'allowPreorders',
  'quantity',
  'inboundQuantity',
  'inboundDeliveryDate',
  'despatchDelayedUntil',
  'currency',
  'subTitle',
  'url',
  'hasPdp',
  'pageMode',
  'icon',
  'swappableParts',
  'frameTypeOverride',
  'preOrderCopyOverride',
  'useBaseProductName',
  'lensNameOverride',
  'limitedEditionLogo',
  'logoWidth',
  'hideLensTech',
  'swatchIconOverride',
  'categories',
  'showPackagingOptions',
  'defaultPackagingOption',
  'enableVirtualTryOn',
  'vtoEffect',
  'vtoVariantName',
  '_vtoTransformation',
  'staticLensTech',
  'productHeroDesigns',
  'oosUnlessInvited',
  'invitationSegment',
  'lineItemContent',
  'showReviews',
  'useGenericReviews',
  '_allReferencingProductReviewAggregations',
  'tagText',
  'tagTheme',
  'includeInProductFeed',
  'partsLimitedEdition',
  'productInfoTitle',
  'promotionProductGroup',
  'promoMessaging',
  'relatedProducts',
  'priceGBP'
]

const PDP_FIELDS = [
  'designImagesOrder',
  'hideSwatches',
  'stockIndicatorThreshold',
  'stockIndicatorThresholdText',
  'stockIndicatorSoldOutText',
  'inTheBoxItems',
  'showDefaultInTheBoxProduct',
  'quotes',
  'quotesSectionTitle',
  'heroFeatures',
  'techSpecs',
  'lensGuideBanner',
  'lensPreviewScenes',
  'showLensPreviewIcons',
  'lensPreviewConditions',
  'rowsAbove',
  'rowsBelow',
  'secondaryProduct',
  'meta',
  'limitedEditions',
  'limitedEditionDescription',
  'secondaryCtaLink',
  'secondaryCtaLinkText',
  'productInfoTitle',
  'productInfoText',
  'pdpText',
  'sizeAndFit',
  'theme',
  'lifestyleImages',
  'lifestyleImagesOverride',
  'promoCapsuleCase',
  'additionalProductSKU',
  'ctaSuffix',
  'showRegisterInterestForm',
  'registerInterestFormCopy',
  'accordion',
  'staticProductImages',
  'showProductInfoTextBlock',
  'imageShowcaseBackgrounds',
  'sampleReview',
  'convincerBlocks'
]

export default class Product {
  constructor(data, includePDPConfig, $abt) {
    this._rawData = data

    PRODUCT_FIELDS.forEach((field) => {
      this[field] = data[field]
    })
    this.compareAtPrice = data.compare_at_amount_float

    // Store PDP blocks if they've been fetched
    if (includePDPConfig) {
      this.pdpLoaded = true
      this.pdpConfig = {}
      PDP_FIELDS.forEach((field) => {
        this.pdpConfig[field] = data[field]
      })
    } else {
      this.pdpLoaded = false
    }

    this.addLensChoiceOverride()
    this.setPrebuiltVariants()

    this.hydrated = true

    this.events = createApp()

    // If data.oosUnlessInvited is true check to see if the user is in the invitation segment using the $abt.variant call
    if ($abt && this.oosUnlessInvited && this.invitationSegment) {
      const invited =
        $abt.variant(this.invitationSegment.splitTestKey) ===
        this.invitationSegment.variantId
      if (!invited) {
        this.oosAsNotInvited = true
        this.quantity = 0
        this.inboundQuantity = 0
        this._rawData.quantity = 0
        this._rawData.inboundQuantity = 0
      } else {
        this.invitedToPreOrder = true
        this._rawData.invitedToPreOrder = true
      }
    }

    // Add any upsells if they're included in the response
    if (data.upsells) {
      this._upsells = data.upsells
        .map((upsell) => new Upsell(upsell))
        .filter((upsell) => upsell.isValidForProduct(this))
    }

    // Trick Vue into thinking this instance already has an observer to prevent it adding a new one
    this.__ob__ = reactive({}).__ob__
    if (this.baseProduct) this.baseProduct.__ob__ = reactive({}).__ob__
  }

  updatePrices() {
    this.basePrice = this._rawData.basePrice
    this.compareAtPrice = this._rawData.compare_at_amount_float
    this.currency = this._rawData.currency
  }

  addParts(getPart) {
    this.parts =
      this._rawData.parts?.map((part) => {
        return getPart(part.id)
      }) || []

    return this.parts
  }

  addLensChoiceOverride() {
    this.lensChoiceOverride = this._rawData.lensChoiceOverride
      ? new PartOption(this._rawData.lensChoiceOverride)
      : null
  }

  get formattedName() {
    return `Formatted name for: ${this.sku}.`
  }

  get allOptions() {
    return Object.fromEntries(
      this.parts
        .map((part) =>
          part.options.map((option) => {
            return [
              [option.sku, option],
              [part.skuPrefix + '_' + option.sku.split('_')[1], option],
            ]
          })
        )
        .flat()
        .flat()
    )
  }

  get pdpConfigWithDefaults() {
    const selectArray = (a, b, fallback = []) => {
      if (a?.length) return a
      if (b?.length) return b
      return fallback
    }

    let ctaSuffix = this.pdpConfig.ctaSuffix
    if (!ctaSuffix && this.pageMode === 'custom') {
      ctaSuffix = this.baseProduct?.pdpConfig?.ctaSuffix
    }

    // If the ctaSuffix is null and there is promoMessaging enabled then use it
    if (
      !ctaSuffix &&
      this.promoMessaging?.enabled &&
      this.promoMessaging?.ctaSuffix
    ) {
      ctaSuffix = this.promoMessaging.ctaSuffix
    }

    return {
      theme: this.pdpConfig.theme || this.baseProduct?.pdpConfig?.theme,
      productInfoTitle: this.pdpConfig.productInfoTitle || this.baseProduct?.pdpConfig?.productInfoTitle,
      productInfoText: this.pdpConfig.productInfoText || this.baseProduct?.pdpConfig?.productInfoText,
      relatedProducts: this.relatedProducts ||
        this.baseProduct?.relatedProducts || { products: [] },
      rowsAbove: selectArray(
        this.pdpConfig.rowsAbove,
        this.baseProduct?.pdpConfig?.rowsAbove
      ),
      rowsBelow: selectArray(
        this.pdpConfig.rowsBelow,
        this.baseProduct?.pdpConfig?.rowsBelow
      ),
      heroFeatures: [
        ...(this.pdpConfig.heroFeatures || []),
        ...(this.baseProduct?.pdpConfig?.heroFeatures || []),
      ],
      quotes: selectArray(
        this.pdpConfig.quotes,
        this.baseProduct?.pdpConfig?.quotes
      ),
      quotesSectionTitle: this.pdpConfig.quotesSectionTitle,
      techSpecs: selectArray(
        this.pdpConfig.techSpecs,
        this.baseProduct?.pdpConfig?.techSpecs
      ),
      designImagesOrder:
        this.pdpConfig.designImagesOrder ||
        this.baseProduct?.pdpConfig?.designImagesOrder ||
        [],
      inTheBoxItems: selectArray(
        this.pdpConfig.inTheBoxItems,
        this.baseProduct?.pdpConfig?.inTheBoxItems,
        []
      ),
      promoCapsuleCaseSKU:
        this.pdpConfig.promoCapsuleCase?.sku ||
        this.baseProduct?.pdpConfig?.promoCapsuleCase?.sku,
      accordion: selectArray(
        this.pdpConfig.accordion,
        this.baseProduct?.pdpConfig?.accordion,
        []
      ),
      hideSwatches:
        this.pdpConfig.hideSwatches ??
        this.baseProduct?.pdpConfig?.hideSwatches ??
        false,
      stockIndicatorThreshold:
        this.pdpConfig.stockIndicatorThreshold ??
        this.baseProduct?.pdpConfig?.stockIndicatorThreshold ??
        0,
      stockIndicatorThresholdText:
        this.pdpConfig.stockIndicatorThresholdText ??
        this.baseProduct?.pdpConfig?.stockIndicatorThresholdText,
      stockIndicatorSoldOutText:
        this.pdpConfig.stockIndicatorSoldOutText ??
        this.baseProduct?.pdpConfig?.stockIndicatorSoldOutText,
      ctaSuffix,
      showDefaultInTheBoxProduct:
        this.pdpConfig.showDefaultInTheBoxProduct ??
        this.baseProduct?.pdpConfig?.showDefaultInTheBoxProduct ??
        true,
      staticProductImages: selectArray(
        this.pdpConfig.staticProductImages,
        this.baseProduct?.pdpConfig?.staticProductImages
      )
    }
  }

  getOptionBySKU(optionSKU) {
    return this.allOptions[optionSKU]
  }

  get legacySKUMapping() {
    if (this._legacySKUMapping) return this._legacySKUMapping
    this._legacySKUMapping = Object.fromEntries(
      this.parts?.map((part) => [part.stockManagedSKUPrefix, part.skuPrefix])
    )
    return this._legacySKUMapping
  }

  _sortChoices(choices) {
    // Choices should be sorted by the order of the parts as defined in Dato (Base Product > Parts) using the `stockManagedSKUPrefix` property.
    // This is the key used in the `choices` object.
    // Which has shape `{ [stockManagedSKUPrefix]: PartOption }`

    // Convert `choices` object into an array and filter out any pairs where the value is falsy.
    const filteredOptions = Object.entries(choices).filter(
      ([_, option]) => option
    )

    // Sort the remaining options based by the index of the corresponding `part` object in the `this.parts` array.
    // The `stockManagedSKUPrefix` property of each `Part` object must match the key in the `choices`.
    const sortedOptions = filteredOptions.sort(([a], [b]) => {
      // Find the index of `a` and `b` in the `this.parts` array based on their `stockManagedSKUPrefix` property.
      const aIndex = this.parts.findIndex(
        (part) => part.stockManagedSKUPrefix === a
      )
      const bIndex = this.parts.findIndex(
        (part) => part.stockManagedSKUPrefix === b
      )
      return aIndex - bIndex
    })

    // Convert the sorted array back into an array of just the values and return it.
    return sortedOptions.reduce((previousValue, [_, option]) => {
      if (option) {
        return previousValue.concat(option)
      }

      return previousValue
    }, [])
  }

  _getSKUFromChoices(base, choices) {
    if (!base || !choices) return null

    const choiceSkus = this._sortChoices(choices || {}).map(
      (choice) => choice.sku
    )

    return [base, ...choiceSkus].join('-')
  }

  _getSKUWithoutLensTech(base, choices) {
    if (!base || !choices) return null

    const choiceSkus = this._sortChoices(choices || {}).map((choice) => {
      if (choice.partType === 'lenses') {
        return getSkuWithoutLensTech(choice.sku)
      }

      return choice.sku
    })

    return [base, ...choiceSkus].join('-')
  }

  getSKUWithoutLensTech(base, choices) {
    return this._getSKUWithoutLensTech(base, choices)
  }

  _getLegacySKUFromChoices(base, choices) {
    if (!base || !choices) return null

    return [
      base,
      ...Object.entries(choices)
        .filter(([_, option]) => option)
        .map(([stockManagedSKUPrefix, option]) => {
          return option.getSKUWithPrefix(
            this.legacySKUMapping[stockManagedSKUPrefix]
          )
        }),
    ].join('-')
  }

  _getNormalisedSKU(base, choices) {
    if (!base || !choices) return null

    const swappablePattern = new RegExp(
      this.swappableParts
        .flat()
        .map((prefix) => `${prefix}_`)
        .join('|')
    )

    // Iterate over choices
    const parts = Object.entries(choices)
      .map(([stockManagedSKUPrefix, option]) => {
        if (!option) return null

        // Get corresponding part
        const part = this.parts.find(
          (part) => part.stockManagedSKUPrefix === stockManagedSKUPrefix
        )

        if (!part) return

        // If option is a string then find the choice instance
        if (typeof option === 'string') {
          option = part.options.find((opt) => opt.sku.endsWith(`_${option}`))
        }

        // If the part has excludeFromAdFeeds set to true, skips to the next choice (e.g. icons/logos)
        if (part?.excludeFromAdFeeds) return null

        // Ignore lens techs, returns the skuWithoutLensTech property of the option
        if (option?.partType === 'lenses') return option?.skuWithoutLensTech

        return option?.sku
      })
      .filter((option) => option)
      .map((optionSKU) => {
        if (this.swappableParts.length) {
          return optionSKU.replace(swappablePattern, 'swap_')
        } else {
          return optionSKU
        }
      })

    return [
      base,
      ...[...new Set(parts)].sort((a, b) => a.localeCompare(b)),
    ].join('-')
  }

  _getAdFeedSKU(base, choices) {
    if (!base || !choices) return null

    // If this product isn't part of an ad feed, return null
    if (!this.includeInProductFeed) return null

    const parts = this.parts
      .map((part) => {
        const option = choices[part.stockManagedSKUPrefix]
        if (!option) return false
        if (part.excludeFromAdFeeds) return false

        return part.skuPrefix + '_' + option.sku.split('_')[1]
      })
      .filter((part) => part)

    return [base, ...parts].join('-')
  }

  _buildHeroDesigns(productTypeFilter) {
    if (this.baseProduct?.heroDesigns?.length && !productTypeFilter)
      return this.baseProduct.heroDesigns

    const heroDesigns = this.productHeroDesigns || []
    const customHeroDesigns = heroDesigns
      .filter(({ enabled }) => enabled)
      .map((design) => {
        // Get instances of each option in this design
        const choices = Object.fromEntries(
          design.parts
            .map(({ sku: optionSKU }) => {
              let [optionSKUPrefix, optionSKUSuffix] = optionSKU.split('_')

              if (optionSKUPrefix === 'c4f') {
                // Handle transition from c3f to c4f
                optionSKUPrefix = 'c3f'
              }

              const part = this.parts.find((part) => {
                return (
                  part.skuPrefix === optionSKUPrefix ||
                  part.stockManagedSKUPrefix === optionSKUPrefix
                )
              })

              if (
                productTypeFilter === TAG_PRESCRIPTION &&
                part.type === 'lenses'
              ) {
                optionSKUSuffix = optionSKUSuffix.replace(/(P|N|8P|8)/g, 'STRX')
              } else if (
                productTypeFilter === TAG_EYEGLASSES &&
                part.type === 'lenses'
              ) {
                optionSKUSuffix = optionSKUSuffix.replace(/(P|N|8P|8)/g, 'STCRX')
              }

              let choice = part?.options.find(
                (option) => option.sku.endsWith(`_${optionSKUSuffix}`)
              )
              if (!choice) return null

              /**
               * Prefer non-infinite products until they sell out
               */
              if (choice.isRecycled) {
                const nonInfAlternative = part.getNonInfiniteAlternative(choice)
                if (nonInfAlternative && nonInfAlternative.isInStock) {
                  choice = nonInfAlternative
                }
              } else if (choice.isNonRecycled && !choice.isInStock) {
                const infAlternative = part.getInfiniteAlternative(choice)
                if (infAlternative && infAlternative.isInStock) {
                  choice = infAlternative
                }
              }

              return [part.stockManagedSKUPrefix, choice]
            })
            .filter((choice) => choice)
        )

        const options = Object.values(choices)

        const frameOption = options.find(
          (option) => option.partType === 'frame'
        )
        const lensOption = options.find(
          (option) => option.partType === 'lenses'
        )

        const iconOption = options.find((option) => option.partType === 'icon')

        const normalisedSKU = this._getNormalisedSKU(this.sku, choices)
        const sku = this._getSKUFromChoices(this.sku, choices)
        const skuWithoutLensTech = this._getSKUWithoutLensTech(
          this.sku,
          choices
        )

        let swatches = [
          frameOption?.swatch,
          (productTypeFilter !== TAG_EYEGLASSES && lensOption?.swatch
            ? lensOption.swatch
            : null),
          (productTypeFilter === TAG_EYEGLASSES && iconOption?.swatch
            ? iconOption.swatch
            : null),
        ].filter(a => a)
        if (design.swatchMode === 'frame' && frameOption?.swatch) {
          swatches = [frameOption.swatch]
        }

        return {
          ...design,
          sku,
          skuWithoutLensTech,
          legacySKU: this._getLegacySKUFromChoices(this.sku, choices),
          normalisedSKU,
          options,
          frameOption,
          lensOption,
          swatches,
          price:
            this.basePrice +
            options.reduce((sum, option) => sum + option.price, 0),
          compareAtPrice:
            this.compareAtPrice +
            options.reduce((sum, option) => sum + option.compareAtPrice, 0),
          isInStock: options.every((option) => option.isInStock),
          isInStockOrOnPreorder: options.every(
            (option) => option.isInStock || option.isOnPreorder
          ),
          isRecycled: frameOption?.isRecycled,
          isIrisLens: lensOption?.isIrisLens,
          url: `${this.url}?sku=${sku}`,
        }
      })
      .filter((design, index, self) => {
        return (
          design.isInStockOrOnPreorder &&
          self.findIndex((d) => d.sku === design.sku) === index
        )
      })

    const limitedEditions = this.limitedEditions

    const targetLength = 10
    return [
      ...customHeroDesigns.slice(0, targetLength - limitedEditions.length),
      ...limitedEditions,
    ]
  }

  get prescriptionHeroDesigns() {
    return this._buildHeroDesigns(TAG_PRESCRIPTION)
  }

  get eyeglassesHeroDesigns() {
    return this._buildHeroDesigns(TAG_EYEGLASSES)
  }

  get heroDesigns() {
    return this._buildHeroDesigns()
  }

  get heroFeatures() {
    return [
      ...(this.pdpConfig.heroFeatures || []),
      ...(this.baseProduct?.pdpConfig?.heroFeatures || []),
    ]
  }

  get limitedEditions() {
    return (
      this.pdpConfig?.limitedEditions?.map((limitedEdition) => {
        return {
          sku: limitedEdition.customisedProduct.sku,
          legacySKU: limitedEdition.customisedProduct.sku,
          normalisedSKU: this._getNormalisedSKU(
            this.sku.split('-')[0],
            Object.fromEntries(limitedEdition.customisedProduct.sku.split('-').slice(1).map(
              skuPart => skuPart.split('_')
            ))
          ),
          options: [],
          frameOption: null,
          lensOption: null,
          swatches: [null, null],
          swatchIcon:
            limitedEdition.customisedProduct?.baseProduct?.swatchIconOverride
              ?.url ||
            limitedEdition.customisedProduct?.swatchIconOverride?.url,
          price: null,
          isInStock: true,
          isRecycled: false,
          isIrisLens: false,
          isLimitedEdition: true,
          isPartBasedLimitedEdition:
            limitedEdition.customisedProduct?.partsLimitedEdition
            || limitedEdition.isCustomisable,
          url: limitedEdition.customisedProduct?.baseProduct?.url,
        }
      }) || []
    )
  }

  get baseSKU() {
    return this.baseProduct?.sku || this.sku
  }

  get allowLensTechFiltering() {
    if (this.isLimitedEdition) return false
    return [
      'classics3',
      'sierras',
      'renegades',
      'tempests',
      'zephyrs',
      'vanguards',
      'ullrs',
      'snipers',
      'tokas',
      'miras'
    ].includes(this.baseSKU)
  }

  get isLimitedEdition() {
    if (this.sku === 'egiftcard') return false
    return this.sku.includes('-le_')
  }

  get minimumPrice() {
    if (this.swappableParts.length)
      throw new Error('Cannot calculate minimumPrice for swappable products')
    return this.parts
      .map((part) => {
        return part.minimumPrice
      })
      .reduce((sum, val) => sum + val, 0)
  }

  get minimumCompareAtPrice() {
    if (this.swappableParts.length)
      throw new Error(
        'Cannot calculate minimumCompareAtPrice for swappable products'
      )
    return this.parts
      .map((part) => {
        return part.minimumCompareAtPrice
      })
      .reduce((sum, val) => sum + val, 0)
  }

  get isInStock() {
    return this.doNotTrackStock || this.quantity > 0
  }

  get isOnPreorder() {
    return (
      !this.isInStock && this.inboundDeliveryDate && this.allowPreorders
    )
  }

  get show360() {
    return hasLayerTemplate(this.sku)
  }

  get layerTemplate() {
    return allLayerTemplates[this.sku]
  }

  get hasVTO() {
    if (this.baseProduct?.hasVTO && this.vtoVariantName) return true

    return this.enableVirtualTryOn !== null && this.vtoEffect !== null
  }

  get vtoTransformation() {
    if (
      !this.hasVTO ||
      !this._vtoTransformation ||
      !this._vtoTransformation?.length
    )
      return null

    const {
      moveX,
      moveY,
      moveZ,
      rotateX,
      rotateY,
      rotateZ,
      scaleX,
      scaleY,
      scaleZ,
    } = this._vtoTransformation[0]
    return {
      move: [moveX || 0, moveY || 0, moveZ || 0],
      rotate: [rotateX || 0, rotateY || 0, rotateZ || 0],
      scale: [scaleX || 1, scaleY || 1, scaleZ || 1],
    }
  }

  get upsells() {
    const upsells = this._upsells || []

    // For limited editions we can also include upsells from the base product
    if (this.isLimitedEdition && this.baseProduct?.upsells?.length) {
      upsells.push(...this.baseProduct.upsells)
    }

    return upsells
  }

  get reviewSummary() {
    return this._allReferencingProductReviewAggregations
  }

  get tagTextWithOverride() {
    if (this.tagText) return this.tagText
    if (this.promoMessaging?.enabled && this.promoMessaging?.tagText) {
      return this.promoMessaging.tagText
    }
    return null
  }

  setPrebuiltVariants () {
    const currency = this.currency
    this.prebuiltVariants = this._rawData.prebuiltVariants?.map((variant) => {
      return {
        ...variant,
        price: variant.prices[currency],
        compareAtPrice: variant.compare_at_prices[currency]
      }
    }) || []
  }

  updateFromCLResponse(response) {
    // We can update stock and price information from the response
    // this is useful for fast moving products.

    if (this.oosAsNotInvited) {
      return // Don't update if the user isn't invited as we've overridden stock here
    }

    const products = response.get([
      'code',
      'stock_items.metadata',
      'stock_items.quantity',
      'stock_items.reserved_stock.quantity',
    ])

    const product = products.find((product) => product.code === this.sku)
    if (!product) return

    const defaultStockItem = product.stock_items?.find(
      (stockItem) =>
        !stockItem?.metadata?.is_inbound ||
        stockItem?.metadata?.is_inbound === 'false'
    )
    const inboundStockItem = product.stock_items?.find(
      (stockItem) =>
        stockItem?.metadata?.is_inbound ||
        stockItem?.metadata?.is_inbound === 'true'
    )

    this.quantity =
      (defaultStockItem?.quantity || 0) -
      (defaultStockItem?.reserved_stock?.quantity || 0)
    this.inboundQuantity = inboundStockItem?.quantity || 0
    this.inboundDeliveryDate = inboundStockItem?.metadata?.delivery_date
    this.despatchDelayedUntil = product.metadata?.delay_despatch_until
  }

  toJSON() {
    return {
      pdpLoaded: this.pdpLoaded,
      _rawData: this._rawData,
    }
  }

  static fromJSON(data, getPart) {
    const product = new Product(data._rawData, data.pdpLoaded)
    product.addParts(getPart)

    if (data._rawData.baseProduct) {
      product.baseProduct = new Product(
        data._rawData.baseProduct,
        data.pdpLoaded
      )
      product.baseProduct.addParts(getPart)
    }

    return product
  }
}
