import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU,
  getSKUWithoutPrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Idarkgrey_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Inavy_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Iblack_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mpink_{nnn}.png'
    },
    Mred: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mred_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mwhite_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mstone_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcf/vcf_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/vcf_Mslateblue_{nnn}.png'
    },
    tekkerz24: {
      pathTemplate: 'gs://sg360/240709/Vu_fTekkerz-{nnn}B.png'
    }
  }
}

export const bottom = {
  id: 'bottom',
  name: 'Bottom',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Idarkgrey_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Iblack_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Inavy_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mpink_{nnn}.png'
    },
    Mred: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mred_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mwhite_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mstone_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcb/vcb_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/vcb_Mslateblue_{nnn}.png'
    }
  }
}

export const nose = {
  id: 'nose',
  name: 'Nose',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Idarkgrey_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Iblack_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Inavy_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mpink_{nnn}.png'
    },
    Mred: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mred_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mstone_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mwhite_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcn/vcn_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/vcn_Mslateblue_{nnn}.png'
    }
  }
}

export const earsocks = {
  id: 'earsocks',
  name: 'Earsocks',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Rblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rblack_{nnn}.png'
    },
    Rblue: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rblue_{nnn}.png'
    },
    Rgold: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rgold_{nnn}.png'
    },
    Rgrey: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rgrey_{nnn}.png'
    },
    Rnavy: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rnavy_{nnn}.png'
    },
    Rstone: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rstone_{nnn}.png'
    },
    Rred: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rred_{nnn}.png'
    },
    Rmint: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rmint_{nnn}.png'
    },
    Rwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vces/vces_Rwhite_{nnn}.png'
    },
    Rslateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/vces_Rslateblue_{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bgold_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bgunmetal_{nnn}.png'
    },
    Bpurple: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bpurple_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bred_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Brosegold_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vci/vci_Bwhite_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    clearTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_clear_{nnn}.png'
    },
    fireTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_fire_{nnn}.png'
    },
    goldTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_gold_{nnn}.png'
    },
    greenTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_green_{nnn}.png'
    },
    hvblueTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_hvblue_{nnn}.png'
    },
    IRhvblueTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_IRhvblue_{nnn}.png'
    },
    IRsmokeTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_IRsmoke_{nnn}.png'
    },
    purpleTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_purple_{nnn}.png'
    },
    silverblueTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_silverblue_{nnn}.png'
    },
    smokeTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_smoke_{nnn}.png'
    },
    roseTF: {
      pathTemplate: 'gs://sg360/240815/Vu_tfrose-{nnn}.png'
    },
    chromeTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/TF/vcle_TF_chrome_{nnn}.png'
    },
    clearFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_clear_{nnn}.png'
    },
    fireFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_fire_{nnn}.png'
    },
    goldFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_gold_{nnn}.png'
    },
    greenFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_green_{nnn}.png'
    },
    hvblueFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_hvblue_{nnn}.png'
    },
    IRhvblueFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_IRhvblue_{nnn}.png'
    },
    IRsmokeFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_IRsmoke_{nnn}.png'
    },
    purpleFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_purple_{nnn}.png'
    },
    silverblueFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_silverblue_{nnn}.png'
    },
    smokeFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_smoke_{nnn}.png'
    },
    roseFF: {
      pathTemplate: 'gs://sg360/240815/Vu_ffrose-{nnn}.png'
    },
    chromeFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle/FF/vcle_FF_chrome_{nnn}.png'
    },
    chrome4FF: {
      pathTemplate: 'gs://sg360/240815/vcle_FF_chrome4_{nnn}.png'
    },
    chrome4TF: {
      pathTemplate: 'gs://sg360/240815/vcle_TF_chrome4_{nnn}.png'
    }
  }
}

export const iris = {
  id: 'iris',
  name: 'Iris Mask',
  imgproxySettings,
  mobileImgproxySettings,
  isIrisMask: true,
  options: {
    none: {
      disabled: true
    },
    FF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/Iris Mask/FF/vcle_Iris Mask_FF_{nnn}.png'
    },
    TF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/Iris Mask/TF/vcle_Iris Mask_TF_{nnn}.png'
    }
  }
}

export const reflection = {
  id: 'reflections',
  isReflection: true,
  name: 'Reflections',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    r5pcTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle Cloud Overlay/5/vcle_TF_Cloud Overlay 5_{nnn}.png'
    },
    r10pcTF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle Cloud Overlay/10/vcle_TF_Cloud Overlay 10_{nnn}.png'
    },
    r5pcFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle Cloud Overlay/FF/5/vcle_FF_Cloud Overlay 5_{nnn}.png'
    },
    r10pcFF: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vcle Cloud Overlay/FF/10/vcle_FF_Cloud Overlay 10_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220520/Vulcans/vclg/vclg_N_{nnn}.png'
    }
  }
}

function resolveOptions (customisedProduct) {
  const design = customisedProduct.state.choices

  return {
    frame: getSKUWithoutPrefix(design.vcf),
    bottom: getSKUWithoutPrefix(design.cb) || 'none',
    nose: getSKUWithoutPrefix(design.cn) || 'none',
    icons: getSKUWithoutPrefix(design.i),
    engraving: 'eN',
    earsocks: getSKUWithoutPrefix(design.es),
    lens: getSKUWithoutPrefix(customisedProduct.lensChoice.skuWithoutLensTech) + customisedProduct.frameType.toUpperCase(),
    iris: customisedProduct.frameType.toUpperCase(),
    reflections: 'r5pc' + customisedProduct.frameType.toUpperCase()
  }
}

function resolveSecondaryOptions (customisedProduct) {
  const design = customisedProduct.state.secondaryChoices
  return {
    bottom: getSKUWithoutPrefix(design.cb) || 'none',
    nose: getSKUWithoutPrefix(design.cn) || 'none'
  }
}

export default {
  supports360: false,
  layers: [
    background,
    frame,
    earsocks,
    bottom,
    nose,
    icons,
    lenses,
    iris,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveSecondaryOptions,
  resolveOptionsFromSKU
}