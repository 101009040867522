import { default as _91orderid_93TWYnuZYfkyMeta } from "/vercel/path0/pages/[locale]/account/dashboard/orders/[orderid].vue?macro=true";
import { default as indexx6FCYQssO9Meta } from "/vercel/path0/pages/[locale]/account/dashboard/returns/index.vue?macro=true";
import { default as dashboard7p65x1DU0OMeta } from "/vercel/path0/pages/[locale]/account/dashboard.vue?macro=true";
import { default as loginXgf4bfo4HpMeta } from "/vercel/path0/pages/[locale]/account/login.vue?macro=true";
import { default as careers8LVliREKQUMeta } from "/vercel/path0/pages/[locale]/careers.vue?macro=true";
import { default as prescriptionHtDrwzlTaxMeta } from "/vercel/path0/pages/[locale]/checkout/prescription.vue?macro=true";
import { default as _91article_93DXppt6cJfeMeta } from "/vercel/path0/pages/[locale]/explore/[category]/[article].vue?macro=true";
import { default as index6qPaNkZD7LMeta } from "/vercel/path0/pages/[locale]/explore/[category]/index.vue?macro=true";
import { default as indexJAZPEVGjNpMeta } from "/vercel/path0/pages/[locale]/explore/index.vue?macro=true";
import { default as _91_46_46_46filter_45page_935BTtp9ZRnyMeta } from "/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue?macro=true";
import { default as _91_46_46_46generic_45landing_45page_933mULUkjA31Meta } from "/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue?macro=true";
import { default as giftsujjgOGbFC8Meta } from "/vercel/path0/pages/[locale]/gifts.vue?macro=true";
import { default as indexy0XS6zLb0pMeta } from "/vercel/path0/pages/[locale]/index.vue?macro=true";
import { default as _91slug_93dsFj9paSqbMeta } from "/vercel/path0/pages/[locale]/info/[slug].vue?macro=true";
import { default as mission13GFiC05r3Meta } from "/vercel/path0/pages/[locale]/mission.vue?macro=true";
import { default as _91product_93pKHp3s3SyYMeta } from "/vercel/path0/pages/[locale]/owners/[product].vue?macro=true";
import { default as indexlv1vQdwSSyMeta } from "/vercel/path0/pages/[locale]/owners/index.vue?macro=true";
import { default as ownersJ0vJEOiiEjMeta } from "/vercel/path0/pages/[locale]/owners.vue?macro=true";
import { default as _91slug_93PiN59E4KP2Meta } from "/vercel/path0/pages/[locale]/register/[slug].vue?macro=true";
import { default as reviewsrEPIUxVTPxMeta } from "/vercel/path0/pages/[locale]/reviews.vue?macro=true";
import { default as indexVFGh14RhQYMeta } from "/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue?macro=true";
import { default as indexylUyG9oox5Meta } from "/vercel/path0/pages/[locale]/shop/[category]/index.vue?macro=true";
import { default as instagramdJmVMJgWaqMeta } from "/vercel/path0/pages/[locale]/shop/instagram.vue?macro=true";
import { default as redirectXANEz9XQ3aMeta } from "/vercel/path0/pages/[locale]/shop/redirect.vue?macro=true";
import { default as sitemapGsJOLVDcgGMeta } from "/vercel/path0/pages/[locale]/sitemap.vue?macro=true";
import { default as indexte6WvDCQ1xMeta } from "/vercel/path0/pages/[locale]/support/[category]/[article]/index.vue?macro=true";
import { default as _91category_93aslyJ5olVcMeta } from "/vercel/path0/pages/[locale]/support/[category].vue?macro=true";
import { default as index3MomKvaJbLMeta } from "/vercel/path0/pages/[locale]/support/contact-us/index.vue?macro=true";
import { default as sentn6NcvVERYFMeta } from "/vercel/path0/pages/[locale]/support/contact-us/sent.vue?macro=true";
import { default as supportLTBaAnrQIfMeta } from "/vercel/path0/pages/[locale]/support.vue?macro=true";
import { default as _91slug_93nA585D1AOqMeta } from "/vercel/path0/pages/[locale]/typeform/[slug].vue?macro=true";
import { default as welcomedybhWXmqThMeta } from "/vercel/path0/pages/[locale]/welcome.vue?macro=true";
export default [
  {
    name: dashboard7p65x1DU0OMeta?.name ?? "locale-account-dashboard",
    path: dashboard7p65x1DU0OMeta?.path ?? "/:locale()/account/dashboard",
    children: [
  {
    name: _91orderid_93TWYnuZYfkyMeta?.name ?? "locale-account-dashboard-orders-orderid",
    path: _91orderid_93TWYnuZYfkyMeta?.path ?? "orders/:orderid()",
    meta: _91orderid_93TWYnuZYfkyMeta || {},
    alias: _91orderid_93TWYnuZYfkyMeta?.alias || [],
    redirect: _91orderid_93TWYnuZYfkyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/account/dashboard/orders/[orderid].vue").then(m => m.default || m)
  },
  {
    name: indexx6FCYQssO9Meta?.name ?? "locale-account-dashboard-returns",
    path: indexx6FCYQssO9Meta?.path ?? "returns",
    meta: indexx6FCYQssO9Meta || {},
    alias: indexx6FCYQssO9Meta?.alias || [],
    redirect: indexx6FCYQssO9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/account/dashboard/returns/index.vue").then(m => m.default || m)
  }
],
    meta: dashboard7p65x1DU0OMeta || {},
    alias: dashboard7p65x1DU0OMeta?.alias || [],
    redirect: dashboard7p65x1DU0OMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: loginXgf4bfo4HpMeta?.name ?? "locale-account-login",
    path: loginXgf4bfo4HpMeta?.path ?? "/:locale()/account/login",
    meta: loginXgf4bfo4HpMeta || {},
    alias: loginXgf4bfo4HpMeta?.alias || [],
    redirect: loginXgf4bfo4HpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/account/login.vue").then(m => m.default || m)
  },
  {
    name: careers8LVliREKQUMeta?.name ?? "locale-careers",
    path: careers8LVliREKQUMeta?.path ?? "/:locale()/careers",
    meta: careers8LVliREKQUMeta || {},
    alias: careers8LVliREKQUMeta?.alias || [],
    redirect: careers8LVliREKQUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/careers.vue").then(m => m.default || m)
  },
  {
    name: prescriptionHtDrwzlTaxMeta?.name ?? "locale-checkout-prescription",
    path: prescriptionHtDrwzlTaxMeta?.path ?? "/:locale()/checkout/prescription",
    meta: prescriptionHtDrwzlTaxMeta || {},
    alias: prescriptionHtDrwzlTaxMeta?.alias || [],
    redirect: prescriptionHtDrwzlTaxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/checkout/prescription.vue").then(m => m.default || m)
  },
  {
    name: _91article_93DXppt6cJfeMeta?.name ?? "locale-explore-category-article",
    path: _91article_93DXppt6cJfeMeta?.path ?? "/:locale()/explore/:category()/:article()",
    meta: _91article_93DXppt6cJfeMeta || {},
    alias: _91article_93DXppt6cJfeMeta?.alias || [],
    redirect: _91article_93DXppt6cJfeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/[article].vue").then(m => m.default || m)
  },
  {
    name: index6qPaNkZD7LMeta?.name ?? "locale-explore-category",
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/:category()",
    meta: index6qPaNkZD7LMeta || {},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAZPEVGjNpMeta?.name ?? "locale-explore",
    path: indexJAZPEVGjNpMeta?.path ?? "/:locale()/explore",
    meta: indexJAZPEVGjNpMeta || {},
    alias: indexJAZPEVGjNpMeta?.alias || [],
    redirect: indexJAZPEVGjNpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "locale-filter-page-filterpage",
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/filter-page/:filterpage(.*)*",
    meta: _91_46_46_46filter_45page_935BTtp9ZRnyMeta || {},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "locale-generic-landing-page-genericlandingpage",
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/generic-landing-page/:genericlandingpage(.*)*",
    meta: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    name: giftsujjgOGbFC8Meta?.name ?? "locale-gifts",
    path: giftsujjgOGbFC8Meta?.path ?? "/:locale()/gifts",
    meta: giftsujjgOGbFC8Meta || {},
    alias: giftsujjgOGbFC8Meta?.alias || [],
    redirect: giftsujjgOGbFC8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/gifts.vue").then(m => m.default || m)
  },
  {
    name: indexy0XS6zLb0pMeta?.name ?? "locale",
    path: indexy0XS6zLb0pMeta?.path ?? "/:locale()",
    meta: indexy0XS6zLb0pMeta || {},
    alias: indexy0XS6zLb0pMeta?.alias || [],
    redirect: indexy0XS6zLb0pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dsFj9paSqbMeta?.name ?? "locale-info-slug",
    path: _91slug_93dsFj9paSqbMeta?.path ?? "/:locale()/info/:slug()",
    meta: _91slug_93dsFj9paSqbMeta || {},
    alias: _91slug_93dsFj9paSqbMeta?.alias || [],
    redirect: _91slug_93dsFj9paSqbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: mission13GFiC05r3Meta?.name ?? "locale-mission",
    path: mission13GFiC05r3Meta?.path ?? "/:locale()/mission",
    meta: mission13GFiC05r3Meta || {},
    alias: mission13GFiC05r3Meta?.alias || [],
    redirect: mission13GFiC05r3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/mission.vue").then(m => m.default || m)
  },
  {
    path: ownersJ0vJEOiiEjMeta?.path ?? "/:locale()/owners",
    children: [
  {
    name: _91product_93pKHp3s3SyYMeta?.name ?? "locale-owners-product",
    path: _91product_93pKHp3s3SyYMeta?.path ?? ":product()",
    meta: _91product_93pKHp3s3SyYMeta || {},
    alias: _91product_93pKHp3s3SyYMeta?.alias || [],
    redirect: _91product_93pKHp3s3SyYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/owners/[product].vue").then(m => m.default || m)
  },
  {
    name: indexlv1vQdwSSyMeta?.name ?? "locale-owners",
    path: indexlv1vQdwSSyMeta?.path ?? "",
    meta: indexlv1vQdwSSyMeta || {},
    alias: indexlv1vQdwSSyMeta?.alias || [],
    redirect: indexlv1vQdwSSyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/owners/index.vue").then(m => m.default || m)
  }
],
    name: ownersJ0vJEOiiEjMeta?.name ?? undefined,
    meta: ownersJ0vJEOiiEjMeta || {},
    alias: ownersJ0vJEOiiEjMeta?.alias || [],
    redirect: ownersJ0vJEOiiEjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/owners.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PiN59E4KP2Meta?.name ?? "locale-register-slug",
    path: _91slug_93PiN59E4KP2Meta?.path ?? "/:locale()/register/:slug()",
    meta: _91slug_93PiN59E4KP2Meta || {},
    alias: _91slug_93PiN59E4KP2Meta?.alias || [],
    redirect: _91slug_93PiN59E4KP2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/register/[slug].vue").then(m => m.default || m)
  },
  {
    name: reviewsrEPIUxVTPxMeta?.name ?? "locale-reviews",
    path: reviewsrEPIUxVTPxMeta?.path ?? "/:locale()/reviews",
    meta: reviewsrEPIUxVTPxMeta || {},
    alias: reviewsrEPIUxVTPxMeta?.alias || [],
    redirect: reviewsrEPIUxVTPxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/reviews.vue").then(m => m.default || m)
  },
  {
    name: indexVFGh14RhQYMeta?.name ?? "locale-shop-category-product",
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/:category()/:product()",
    meta: indexVFGh14RhQYMeta || {},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: indexylUyG9oox5Meta?.name ?? "locale-shop-category",
    path: indexylUyG9oox5Meta?.path ?? "/:locale()/shop/:category()",
    meta: indexylUyG9oox5Meta || {},
    alias: indexylUyG9oox5Meta?.alias || [],
    redirect: indexylUyG9oox5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: instagramdJmVMJgWaqMeta?.name ?? "locale-shop-instagram",
    path: instagramdJmVMJgWaqMeta?.path ?? "/:locale()/shop/instagram",
    meta: instagramdJmVMJgWaqMeta || {},
    alias: instagramdJmVMJgWaqMeta?.alias || [],
    redirect: instagramdJmVMJgWaqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/instagram.vue").then(m => m.default || m)
  },
  {
    name: redirectXANEz9XQ3aMeta?.name ?? "locale-shop-redirect",
    path: redirectXANEz9XQ3aMeta?.path ?? "/:locale()/shop/redirect",
    meta: redirectXANEz9XQ3aMeta || {},
    alias: redirectXANEz9XQ3aMeta?.alias || [],
    redirect: redirectXANEz9XQ3aMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/redirect.vue").then(m => m.default || m)
  },
  {
    name: sitemapGsJOLVDcgGMeta?.name ?? "locale-sitemap",
    path: sitemapGsJOLVDcgGMeta?.path ?? "/:locale()/sitemap",
    meta: sitemapGsJOLVDcgGMeta || {},
    alias: sitemapGsJOLVDcgGMeta?.alias || [],
    redirect: sitemapGsJOLVDcgGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/sitemap.vue").then(m => m.default || m)
  },
  {
    name: supportLTBaAnrQIfMeta?.name ?? "locale-support",
    path: supportLTBaAnrQIfMeta?.path ?? "/:locale()/support",
    children: [
  {
    name: _91category_93aslyJ5olVcMeta?.name ?? "locale-support-category",
    path: _91category_93aslyJ5olVcMeta?.path ?? ":category()",
    children: [
  {
    name: indexte6WvDCQ1xMeta?.name ?? "locale-support-category-article",
    path: indexte6WvDCQ1xMeta?.path ?? ":article()",
    meta: indexte6WvDCQ1xMeta || {},
    alias: indexte6WvDCQ1xMeta?.alias || [],
    redirect: indexte6WvDCQ1xMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/support/[category]/[article]/index.vue").then(m => m.default || m)
  }
],
    meta: _91category_93aslyJ5olVcMeta || {},
    alias: _91category_93aslyJ5olVcMeta?.alias || [],
    redirect: _91category_93aslyJ5olVcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/support/[category].vue").then(m => m.default || m)
  },
  {
    name: index3MomKvaJbLMeta?.name ?? "locale-support-contact-us",
    path: index3MomKvaJbLMeta?.path ?? "contact-us",
    meta: index3MomKvaJbLMeta || {},
    alias: index3MomKvaJbLMeta?.alias || [],
    redirect: index3MomKvaJbLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/support/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: sentn6NcvVERYFMeta?.name ?? "locale-support-contact-us-sent",
    path: sentn6NcvVERYFMeta?.path ?? "contact-us/sent",
    meta: sentn6NcvVERYFMeta || {},
    alias: sentn6NcvVERYFMeta?.alias || [],
    redirect: sentn6NcvVERYFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/support/contact-us/sent.vue").then(m => m.default || m)
  }
],
    meta: supportLTBaAnrQIfMeta || {},
    alias: supportLTBaAnrQIfMeta?.alias || [],
    redirect: supportLTBaAnrQIfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nA585D1AOqMeta?.name ?? "locale-typeform-slug",
    path: _91slug_93nA585D1AOqMeta?.path ?? "/:locale()/typeform/:slug()",
    meta: _91slug_93nA585D1AOqMeta || {},
    alias: _91slug_93nA585D1AOqMeta?.alias || [],
    redirect: _91slug_93nA585D1AOqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue").then(m => m.default || m)
  },
  {
    name: welcomedybhWXmqThMeta?.name ?? "locale-welcome",
    path: welcomedybhWXmqThMeta?.path ?? "/:locale()/welcome",
    meta: welcomedybhWXmqThMeta || {},
    alias: welcomedybhWXmqThMeta?.alias || [],
    redirect: welcomedybhWXmqThMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/welcome.vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/running-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__running-sunglasses-shop__running-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"D1Ac0e6CSd2_q8hPstSr2A","slug":"shop__running-sunglasses","index":true,"title":"Sunglasses for Runners | Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/running-sunglasses","slugLocaleMapping":{"shop__running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/cycling-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-cycling-sunglasses-cycling-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"K1bxCC1kQG-C1eohDRpX-w","slug":"cycling-sunglasses","index":true,"title":"Cycling Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/cycling-sunglasses","slugLocaleMapping":{"cycling-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/mclaren/all",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__mclaren__all-shop__mclaren__all",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"bBBdmTiPSGCVDSvICLg09g","slug":"shop__mclaren__all","index":true,"title":"McLaren Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/mclaren/all","slugLocaleMapping":{"shop__mclaren__all":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__sunglasses-shop__sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"OoMqSBLQT-2p2stB20BuFw","slug":"shop__sunglasses","index":true,"title":"All Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/sunglasses","slugLocaleMapping":{"shop__sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/womens-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-womens-sunglasses-womens-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"FPPK7GyYTLqA9cDAr4ScXg","slug":"womens-sunglasses","index":true,"title":"Women's Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/womens-sunglasses","slugLocaleMapping":{"womens-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/mens-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-mens-sunglasses-mens-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"NmMdtJmYTM-dcz68Dtt6HQ","slug":"mens-sunglasses","index":true,"title":"Men's Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mens-sunglasses","slugLocaleMapping":{"mens-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/everyday-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-everyday-sunglasses-everyday-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"cUBx6fiRTX-Kg_4XuKuaLw","slug":"everyday-sunglasses","index":true,"title":"Everyday Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/everyday-sunglasses","slugLocaleMapping":{"everyday-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/baseball-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-baseball-sunglasses-baseball-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"BBDnC-wDQ0iDJmGKlNrGSA","slug":"baseball-sunglasses","index":true,"title":"Baseball Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/baseball-sunglasses","slugLocaleMapping":{"baseball-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/sailing-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-sailing-sunglasses-sailing-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Z_6M0BD2SSeRfWca7CCjsQ","slug":"sailing-sunglasses","index":true,"title":"Sailing Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sailing-sunglasses","slugLocaleMapping":{"sailing-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/polarised-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-polarised-sunglasses-polarised-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"E-29V6AJTNOIfkVQb4j6ew","slug":"polarised-sunglasses","index":true,"title":"Polarised Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/polarised-sunglasses","slugLocaleMapping":{"polarised-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"polarized-sunglasses":["en-us"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/polarized-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-polarised-sunglasses-polarized-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"E-29V6AJTNOIfkVQb4j6ew","slug":"polarized-sunglasses","index":true,"title":"Polarised Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/polarised-sunglasses","slugLocaleMapping":{"polarised-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"polarized-sunglasses":["en-us"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/fishing-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-fishing-sunglasses-fishing-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"VsBWmMr0R4GcDmI8DIpRkQ","slug":"fishing-sunglasses","index":true,"title":"Fishing Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/fishing-sunglasses","slugLocaleMapping":{"fishing-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/driving-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-driving-sunglasses-driving-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"dnhRcmHlT7SIUMZubRhi-g","slug":"driving-sunglasses","index":true,"title":"Sunglasses for Driving: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/driving-sunglasses","slugLocaleMapping":{"driving-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/watersports-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-watersports-sunglasses-watersports-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"CV4M02IZQY-1AXVoZt9qPg","slug":"watersports-sunglasses","index":true,"title":"Watersports Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/watersports-sunglasses","slugLocaleMapping":{"watersports-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/trail-running-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-trail-running-sunglasses-trail-running-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"S1QvilrMRAiPB7QAXswakA","slug":"trail-running-sunglasses","index":true,"title":"Trail Running Sunglasses: Free Pair with Every Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/trail-running-sunglasses","slugLocaleMapping":{"trail-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/road-cycling-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-road-cycling-sunglasses-road-cycling-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"SJzlkf_cSGiMjFYXokLFyg","slug":"road-cycling-sunglasses","index":true,"title":"Road Cycling Sunglasses: Free Pair with Every Pair ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/road-cycling-sunglasses","slugLocaleMapping":{"road-cycling-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/black-friday-2024",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-black-friday-2024-black-friday-2024",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"dnYJkXOvTzSyJ0NL8QbVwg","slug":"black-friday-2024","index":true,"title":"Black Friday Sunglasses Deals 2024: Free Pair with Any Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/black-friday-2024","slugLocaleMapping":{"black-friday-2024":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__goggles-shop__goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Lo6uf7h6R2CwZuFCQBP1Jw","slug":"shop__goggles","index":true,"title":"Ski & Snowboard Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/goggles","slugLocaleMapping":{"shop__goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/summer-solstice",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-summer-solstice-summer-solstice",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"a356UUB5TNSb_yZkZCqfow","slug":"summer-solstice","index":true,"title":"SunGod Solstice Collection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/summer-solstice","slugLocaleMapping":{"summer-solstice":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/custom-performance",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__custom-performance-shop__custom-performance",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Z-6p8eVoSICagoetCcuW9g","slug":"shop__custom-performance","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom-performance","slugLocaleMapping":{"shop__custom-performance":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/ski-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-ski-sunglasses-ski-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"AthaT7h7QBSezTwG7G_teg","slug":"ski-sunglasses","index":true,"title":"Ski Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/ski-sunglasses","slugLocaleMapping":{"ski-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/williams-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-williams-sunglasses-williams-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"VxA8Ib0vRkKDwLEEbKHorg","slug":"williams-sunglasses","index":true,"title":"Williams F1 Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/williams-sunglasses","slugLocaleMapping":{"williams-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/limited-edition",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__limited-edition-shop__limited-edition",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"QXML7NAFRSOi7bBqJQwkHQ","slug":"shop__limited-edition","index":true,"title":"Limited Edition Sunglasses & Ski Goggles ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/limited-edition","slugLocaleMapping":{"shop__limited-edition":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/golf-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-golf-sunglasses-golf-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"XSbrmnBVR6a2qoJXMwcqiA","slug":"golf-sunglasses","index":true,"title":"Polarised Golf Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/golf-sunglasses","slugLocaleMapping":{"golf-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/custom-prescription",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__custom-prescription-shop__custom-prescription",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"HA-sOtn2Q8iLdKtP6IvEaw","slug":"shop__custom-prescription","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom-prescription","slugLocaleMapping":{"shop__custom-prescription":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/prescription-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__prescription-sunglasses-shop__prescription-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Bw3ihGHRR5O2JEizG2kv1Q","slug":"shop__prescription-sunglasses","index":true,"title":"Prescription Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/prescription-sunglasses","slugLocaleMapping":{"shop__prescription-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/pace/womens",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__pace__womens-shop__pace__womens",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Yh7VGsjuT3KfNEpKs9wX3g","slug":"shop__pace__womens","index":true,"title":"Women's Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/womens","slugLocaleMapping":{"shop__pace__womens":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"shop__pace__women":["en-us"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/pace/women",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__pace__womens-shop__pace__women",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Yh7VGsjuT3KfNEpKs9wX3g","slug":"shop__pace__women","index":true,"title":"Women's Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/womens","slugLocaleMapping":{"shop__pace__womens":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"shop__pace__women":["en-us"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/sungod-x-new-era",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-sungod-x-new-era-sungod-x-new-era",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"DhogMTrtT8-d2Ax6mFkKTA","slug":"sungod-x-new-era","index":true,"title":"SunGod x New Era: Limited Edition Headwear Collection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sungod-x-new-era","slugLocaleMapping":{"sungod-x-new-era":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/road-running-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-road-running-sunglasses-road-running-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Vg9BwmOqTUiHGf0PTbth8Q","slug":"road-running-sunglasses","index":true,"title":"Road Running Sunglasses. ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/road-running-sunglasses","slugLocaleMapping":{"road-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/cricket-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-cricket-sunglasses-cricket-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"EOYaatG3SzyUG8g6komQTA","slug":"cricket-sunglasses","index":true,"title":"Sunglasses for Cricket","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/cricket-sunglasses","slugLocaleMapping":{"cricket-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/glasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-glasses-glasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Gb6uuf5GRj-jZm--1QB7Hw","slug":"glasses","index":true,"title":"Prescription Glasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/glasses","slugLocaleMapping":{"glasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/ineos-grenadiers/all",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__ineos-grenadiers__all-shop__ineos-grenadiers__all",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Hgg7SMWQRLS036m9fwjEJw","slug":"shop__ineos-grenadiers__all","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/ineos-grenadiers/all","slugLocaleMapping":{"shop__ineos-grenadiers__all":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/all",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__all-shop__all",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"MipZrn63QvunjF0TkbwwSQ","slug":"shop__all","index":true,"title":"All Sunglasses & Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/all","slugLocaleMapping":{"shop__all":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/mountain-biking-sunglasses-and-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-mountain-biking-sunglasses-and-goggles-mountain-biking-sunglasses-and-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"KTeoDkpQTtWCbACGhEEE9g","slug":"mountain-biking-sunglasses-and-goggles","index":true,"title":"Sunglasses & Goggles for Mountain Biking","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mountain-biking-sunglasses-and-goggles","slugLocaleMapping":{"mountain-biking-sunglasses-and-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/rowing-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-rowing-sunglasses-rowing-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"a2mhQ7ksTxKBRX85ikymzA","slug":"rowing-sunglasses","index":true,"title":"Rowing Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/rowing-sunglasses","slugLocaleMapping":{"rowing-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop-by-sport",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop-by-sport-shop-by-sport",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"CYV7MLqBRBmvAvjkt-mOuw","slug":"shop-by-sport","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop-by-sport","slugLocaleMapping":{"shop-by-sport":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/softball-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-softball-sunglasses-softball-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"RFsoXkrTQO6xRnzRmwr55A","slug":"softball-sunglasses","index":true,"title":"Softball Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/softball-sunglasses","slugLocaleMapping":{"softball-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/mountaineering-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-mountaineering-sunglasses-mountaineering-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"adKUKEJpS5yFUloZmhrfCg","slug":"mountaineering-sunglasses","index":true,"title":"Mountaineering Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mountaineering-sunglasses","slugLocaleMapping":{"mountaineering-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/hiking-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-hiking-sunglasses-hiking-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"P7m2OhBXS7ely_ievDxXTw","slug":"hiking-sunglasses","index":true,"title":"Hiking Sunglasses with 100% UV Protection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/hiking-sunglasses","slugLocaleMapping":{"hiking-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/triathlon-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-triathlon-sunglasses-triathlon-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"bjL6STg-RMm05z4QvM8Qfw","slug":"triathlon-sunglasses","index":true,"title":"Triathlon Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/triathlon-sunglasses","slugLocaleMapping":{"triathlon-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/sunglasses-for-small-faces",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-sunglasses-for-small-faces-sunglasses-for-small-faces",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"RiNVS9jmQmi9dt_plK5yIg","slug":"sunglasses-for-small-faces","index":true,"title":"Sunglasses for Smaller Faces","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sunglasses-for-small-faces","slugLocaleMapping":{"sunglasses-for-small-faces":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/pace/performance-bestseller",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__pace__performance-bestseller-shop__pace__performance-bestseller",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"EtHygREfT1OdKXaq3RwjkA","slug":"shop__pace__performance-bestseller","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/performance-bestseller","slugLocaleMapping":{"shop__pace__performance-bestseller":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/tennis-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-tennis-sunglasses-tennis-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"WchOOw5uRuum00EnLxxbIw","slug":"tennis-sunglasses","index":true,"title":"Tennis Sunglasses | 100% UV Protection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/tennis-sunglasses","slugLocaleMapping":{"tennis-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/custom",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__custom-shop__custom",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"epHdwzXCQ0yOZjwCBfC0hw","slug":"shop__custom","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom","slugLocaleMapping":{"shop__custom":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/custom-lifestyle",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__custom-lifestyle-shop__custom-lifestyle",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Yl9ETsORTE2aqRLyYO60Yw","slug":"shop__custom-lifestyle","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom-lifestyle","slugLocaleMapping":{"shop__custom-lifestyle":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/womens-running-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-womens-running-sunglasses-womens-running-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"YI15u15aTA6Zg-PgImfAiw","slug":"womens-running-sunglasses","index":true,"title":"Women's Running Sunglasses | 100% UV Protected","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/womens-running-sunglasses","slugLocaleMapping":{"womens-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/mens-running-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-mens-running-sunglasses-mens-running-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"RbqWEON0QEaCCjr1B8c7FQ","slug":"mens-running-sunglasses","index":true,"title":"Road Running Sunglasses. ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mens-running-sunglasses","slugLocaleMapping":{"mens-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/sports-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-sports-sunglasses-sports-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"YTa2DaNtQHG0g4DPbC4TPA","slug":"sports-sunglasses","index":true,"title":"Sports Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sports-sunglasses","slugLocaleMapping":{"sports-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/f1-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-f1-sunglasses-f1-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"QNM-o3DWSI2BVQm_snFryg","slug":"f1-sunglasses","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/f1-sunglasses","slugLocaleMapping":{"f1-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/8ko-lifestyle-and-performance-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-8ko-lifestyle-and-performance-sunglasses-8ko-lifestyle-and-performance-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"MzQzRltpSOeEEPbpDp529g","slug":"8ko-lifestyle-and-performance-sunglasses","index":true,"title":"8KO® Sunglasses from SunGod","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/8ko-lifestyle-and-performance-sunglasses","slugLocaleMapping":{"8ko-lifestyle-and-performance-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/8ko-lifestyle-sunglasses",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-8ko-lifestyle-sunglasses-8ko-lifestyle-sunglasses",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"SLTNJ1FFTG6Tz19CnByqvg","slug":"8ko-lifestyle-sunglasses","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/8ko-lifestyle-sunglasses","slugLocaleMapping":{"8ko-lifestyle-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/pace",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__pace-shop__pace",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"b36WQ4wMSMObwkHS8JcPMQ","slug":"shop__pace","index":true,"title":"Performance Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace","slugLocaleMapping":{"shop__pace":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/lifestyle",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__lifestyle-shop__lifestyle",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"IpiceQ9YQGqSAdSnfnN0MQ","slug":"shop__lifestyle","index":true,"title":"Everyday/Active Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/lifestyle","slugLocaleMapping":{"shop__lifestyle":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/lifestyle-8ko-polarised",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-lifestyle-8ko-polarised-lifestyle-8ko-polarised",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Sc-xOE9MQe-5vXpLLqoIjA","slug":"lifestyle-8ko-polarised","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/lifestyle-8ko-polarised","slugLocaleMapping":{"lifestyle-8ko-polarised":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/pace/mens",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__pace__mens-shop__pace__mens",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"C0icLGmrRRWtVrLPSJT4lA","slug":"shop__pace__mens","index":true,"title":"Men's Performance Sunglasses ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/mens","slugLocaleMapping":{"shop__pace__mens":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/limited-edition-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-limited-edition-goggles-limited-edition-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"PcmRMiTTSii3qHdiuNN1ZQ","slug":"limited-edition-goggles","index":true,"title":"Limited Edition Ski & Snowboard Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/limited-edition-goggles","slugLocaleMapping":{"limited-edition-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/8ko-snow-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__8ko-snow-goggles-shop__8ko-snow-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"LOli8PoKRP2JVx61S1Xqwg","slug":"shop__8ko-snow-goggles","index":true,"title":"8KO®snow Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/8ko-snow-goggles","slugLocaleMapping":{"shop__8ko-snow-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/snowboard-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-snowboard-goggles-snowboard-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"Lw_SAQnQQnKHJvZ9kJXbJA","slug":"snowboard-goggles","index":true,"title":"Athlete-Tested Goggles for Snowboarders","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/snowboard-goggles","slugLocaleMapping":{"snowboard-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/ski-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-ski-goggles-ski-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"STKrgISTSh-r7ilfqFcArg","slug":"ski-goggles","index":true,"title":"Ski Goggles with 100% UV Protection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/ski-goggles","slugLocaleMapping":{"ski-goggles":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/accessories",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__accessories-shop__accessories",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"T1yL97j9RnusiDvJvqOX3g","slug":"shop__accessories","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/accessories","slugLocaleMapping":{"shop__accessories":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/custom-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-custom-goggles-custom-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"IdpBrCRpSlGGi1Agw03jSA","slug":"custom-goggles","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/custom-goggles","slugLocaleMapping":{"custom-goggles":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/cases",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__cases-shop__cases",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"INbYYSxCQuiZEhryleBW3g","slug":"shop__cases","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/cases","slugLocaleMapping":{"shop__cases":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/microfibres",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__microfibres-shop__microfibres",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"N4Fc5rBMTfm9sPdDNGI7ag","slug":"shop__microfibres","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/microfibres","slugLocaleMapping":{"shop__microfibres":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/snow-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-snow-goggles-snow-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"QUC63xG1SEKiaFuY3W-ebw","slug":"snow-goggles","index":true,"title":"Snow Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/snow-goggles","slugLocaleMapping":{"snow-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/katie-ormerod/all",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__katie-ormerod__all-shop__katie-ormerod__all",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"EcHgiL4LTIqiAWFzpjmnrg","slug":"shop__katie-ormerod__all","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/katie-ormerod/all","slugLocaleMapping":{"shop__katie-ormerod__all":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/ski-goggle-protectors",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-ski-goggle-protectors-ski-goggle-protectors",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"GsAHxC3vSde730dRHlb9bw","slug":"ski-goggle-protectors","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/ski-goggle-protectors","slugLocaleMapping":{"ski-goggle-protectors":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/pace/performance-limited-edition",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__pace__performance-limited-edition-shop__pace__performance-limited-edition",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"bPceoyyVRMybizU_0znr2A","slug":"shop__pace__performance-limited-edition","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/performance-limited-edition","slugLocaleMapping":{"shop__pace__performance-limited-edition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/1-for-the-planet",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-1-for-the-planet-1-for-the-planet",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"GCs26U0EQI69bRsHkjPzVg","slug":"1-for-the-planet","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/1-for-the-planet","slugLocaleMapping":{"1-for-the-planet":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/surfers-against-sewage",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-surfers-against-sewage-surfers-against-sewage",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"CjyLOiYJQnO0PqR_oSl8-g","slug":"surfers-against-sewage","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/surfers-against-sewage","slugLocaleMapping":{"surfers-against-sewage":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/shop/goggles/bestseller-goggles",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-shop__goggles__bestseller-goggles-shop__goggles__bestseller-goggles",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"IY-gmOusS4KFtLTSKh7aSw","slug":"shop__goggles__bestseller-goggles","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/goggles/bestseller-goggles","slugLocaleMapping":{"shop__goggles__bestseller-goggles":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/collaborations",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-collaborations-collaborations",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"W-I7RgT8QqqavzpEdKJTVw","slug":"collaborations","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/collaborations","slugLocaleMapping":{"collaborations":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.path ?? "/:locale()/protect-our-winters-uk",
    name: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.name ?? "fp-protect-our-winters-uk-protect-our-winters-uk",
    meta: {...(_91_46_46_46filter_45page_935BTtp9ZRnyMeta || {}), ...{"id":"cWFe4wbeQ-ygxW0ZKNGiOg","slug":"protect-our-winters-uk","index":true,"title":"Ski Goggles from Protect Our Winters UK","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/protect-our-winters-uk","slugLocaleMapping":{"protect-our-winters-uk":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.alias || [],
    redirect: _91_46_46_46filter_45page_935BTtp9ZRnyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/black-friday-sunglasses-deals",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-black-friday-sunglasses-deals-black-friday-sunglasses-deals",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"qLyGTTPuSWq-jRYScTAMKg","slug":"black-friday-sunglasses-deals","index":true,"theme":"sungod","title":"Black Friday Sunglasses Deals 2024","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/black-friday-sunglasses-deals","slugLocaleMapping":{"black-friday-sunglasses-deals":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/the-2024-vision-raise",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-the-2024-vision-raise-the-2024-vision-raise",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"ZuInl0d5SN2MpJfaJhQRCQ","slug":"the-2024-vision-raise","index":true,"theme":"sungod-standalone","title":"The 2024 Vision Raise.","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/the-2024-vision-raise","slugLocaleMapping":{"the-2024-vision-raise":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/refer-a-friend",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-refer-a-friend-refer-a-friend",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"On02-9p7QAqVO1tkwbnNxQ","slug":"refer-a-friend","index":true,"theme":"sungod","title":"Refer a Friend","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/refer-a-friend","slugLocaleMapping":{"refer-a-friend":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/competition/strava-challenge/golden-hour",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-competition__strava-challenge__golden-hour-competition__strava-challenge__golden-hour",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"MS9F_fj2QvavA3SaiGI-fQ","slug":"competition__strava-challenge__golden-hour","index":true,"theme":"sungod","title":"Golden Hour Strava Challenge","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/competition/strava-challenge/golden-hour","slugLocaleMapping":{"competition__strava-challenge__golden-hour":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/events-purchase",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-events-purchase-events-purchase",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"bUB_HwiLQRaKrbJZo0l1sw","slug":"events-purchase","index":true,"theme":"sungod","title":"SunGod Lifetime Guarantee Registration - Events Only ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/events-purchase","slugLocaleMapping":{"events-purchase":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/tile-turnaround",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-tile-turnaround-tile-turnaround",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"P4lN7ZadT-G1QpE4ZhAyTw","slug":"tile-turnaround","index":true,"theme":"sungod","title":"Tile Turnaround","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/tile-turnaround","slugLocaleMapping":{"tile-turnaround":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/competition/strava-challenge/factor-50",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-competition__strava-challenge__factor-50-competition__strava-challenge__factor-50",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"dZ7YyNooT_mEgo8Qm0gMng","slug":"competition__strava-challenge__factor-50","index":true,"theme":"sungod","title":"Factor 50 Strava Challenge","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/competition/strava-challenge/factor-50","slugLocaleMapping":{"competition__strava-challenge__factor-50":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/create",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-create-create",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"0Nz-_eJGSzmov0F6HHPt_g","slug":"create","index":true,"theme":"sungod","title":"SunGod Create","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/create","slugLocaleMapping":{"create":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/protect-our-winters",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-protect-our-winters-protect-our-winters",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"72065080","slug":"protect-our-winters","index":true,"theme":"sungod","title":"Protect Our Winters UK","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/protect-our-winters","slugLocaleMapping":{"protect-our-winters":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/one-percent-for-the-planet",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-one-percent-for-the-planet-one-percent-for-the-planet",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"Cbe3Ti4pRVO3MJgxqLiw_g","slug":"one-percent-for-the-planet","index":true,"theme":"sungod","title":"SunGod x 1% for the Planet: Sunglasses and Ski Goggles","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/one-percent-for-the-planet","slugLocaleMapping":{"one-percent-for-the-planet":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/goggles-size-guide",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-goggles-size-guide-goggles-size-guide",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"172974147","slug":"goggles-size-guide","index":true,"theme":"sungod","title":"Ski and Snowboard Goggles Size Guide","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/goggles-size-guide","slugLocaleMapping":{"goggles-size-guide":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/goggles-size-guide/vanguards-vle_fireN-vf_blackM-vs_blackLP",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-goggles-size-guide-goggles-size-guide/vanguards-vle_fireN-vf_blackM-vs_blackLP",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"172974147","slug":"goggles-size-guide","index":false,"theme":"sungod","title":"Ski and Snowboard Goggles Size Guide","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/goggles-size-guide","slugLocaleMapping":{"goggles-size-guide":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]},"sku":"vanguards-vle_fireN-vf_blackM-vs_blackLP"}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/mclaren-winners-sunglasses",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-mclaren-winners-sunglasses-mclaren-winners-sunglasses",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"FvocgP82QTSC8UNRCcRIlg","slug":"mclaren-winners-sunglasses","index":true,"theme":"dark-chrome","title":"McLaren Winners' Collection Sunglasses","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/mclaren-winners-sunglasses","slugLocaleMapping":{"mclaren-winners-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/owners_winners",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-owners_winners-owners_winners",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"xLAD9VvnTg-xi2FL-GRXrA","slug":"owners_winners","index":true,"theme":"sungod","title":"McLaren Winners Owners' Guide ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/owners_winners","slugLocaleMapping":{"owners_winners":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/create-terms-and-conditions",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-create-terms-and-conditions-create-terms-and-conditions",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"ISJ4mHGBT5677kcxf2ygWg","slug":"create-terms-and-conditions","index":true,"theme":"sungod","title":"SunGod Create - Terms & Conditions Of Sale","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/create-terms-and-conditions","slugLocaleMapping":{"create-terms-and-conditions":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/co-brand",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-co-brand-co-brand",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"146687282","slug":"co-brand","index":true,"theme":"sungod","title":"Co-Brand","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/co-brand","slugLocaleMapping":{"co-brand":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/see-better",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-see-better-see-better",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"173170654","slug":"see-better","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/see-better","slugLocaleMapping":{"see-better":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/shop/infinite-recycled-frames",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-shop__infinite-recycled-frames-shop__infinite-recycled-frames",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"63538716","slug":"shop__infinite-recycled-frames","index":true,"theme":"sungod","title":"Sunglasses with 100% Recycled Frames","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/infinite-recycled-frames","slugLocaleMapping":{"shop__infinite-recycled-frames":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/itv-competition",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-itv-competition-itv-competition",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"185268681","slug":"itv-competition","index":true,"theme":"sungod","title":"ITV Competition","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/itv-competition","slugLocaleMapping":{"itv-competition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/sunglasses-size-guide",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-sunglasses-size-guide-sunglasses-size-guide",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"129609117","slug":"sunglasses-size-guide","index":true,"theme":"sungod","title":"Sunglasses Size Guide","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/sunglasses-size-guide","slugLocaleMapping":{"sunglasses-size-guide":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/shop/limited-edition/james-woods",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-shop__limited-edition__james-woods-shop__limited-edition__james-woods",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"95340275","slug":"shop__limited-edition__james-woods","index":true,"theme":"sungod","title":"James 'Woodsy' Woods. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/limited-edition/james-woods","slugLocaleMapping":{"shop__limited-edition__james-woods":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/design-your-pair-challenge",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-design-your-pair-challenge-design-your-pair-challenge",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"173002726","slug":"design-your-pair-challenge","index":true,"theme":"sungod","title":"SunGod. Design Your Pair Strava Challenge. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/design-your-pair-challenge","slugLocaleMapping":{"design-your-pair-challenge":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/TDF",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-TDF-TDF",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"143091794","slug":"TDF","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/TDF","slugLocaleMapping":{"TDF":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/sungod-x-fastandcurious-competition",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-sungod-x-fastandcurious-competition-sungod-x-fastandcurious-competition",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"141946203","slug":"sungod-x-fastandcurious-competition","index":true,"theme":"sungod","title":"McLaren F1 x SunGod Competition. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/sungod-x-fastandcurious-competition","slugLocaleMapping":{"sungod-x-fastandcurious-competition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/spencer",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-spencer-spencer",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"138385272","slug":"spencer","index":true,"theme":"sungod","title":"SunGod x Big Fish Podcast. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/spencer","slugLocaleMapping":{"spencer":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/london",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-london-london",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"130104136","slug":"london","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/london","slugLocaleMapping":{"london":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/tube",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-tube-tube",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"106393254","slug":"tube","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/tube","slugLocaleMapping":{"tube":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/see-better-challenge",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-see-better-challenge-see-better-challenge",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"119019814","slug":"see-better-challenge","index":true,"theme":"sungod","title":"SunGod. See Better Strava Challenge. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/see-better-challenge","slugLocaleMapping":{"see-better-challenge":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/geraint",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-geraint-geraint",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"117978536","slug":"geraint","index":true,"theme":"sungod","title":"Geraint Thomas Cycling Club","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/geraint","slugLocaleMapping":{"geraint":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/the-bike-project",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-the-bike-project-the-bike-project",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"63538691","slug":"the-bike-project","index":true,"theme":"sungod","title":"Meet Our Partners: The Bike Project","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/the-bike-project","slugLocaleMapping":{"the-bike-project":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/shop/limited-edition/katie-ormerod",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-shop__limited-edition__katie-ormerod-shop__limited-edition__katie-ormerod",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"95311247","slug":"shop__limited-edition__katie-ormerod","index":true,"theme":"sungod","title":"Katie Ormerod Signature Series. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/limited-edition/katie-ormerod","slugLocaleMapping":{"shop__limited-edition__katie-ormerod":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/the-2022-vision-raise",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-the-2022-vision-raise-the-2022-vision-raise",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"142780024","slug":"the-2022-vision-raise","index":true,"theme":"sungod-standalone","title":"The 2022 Vision Raise.","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/the-2022-vision-raise","slugLocaleMapping":{"the-2022-vision-raise":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/sungod-x-mclaren-competition",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-sungod-x-mclaren-competition-sungod-x-mclaren-competition",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"123048332","slug":"sungod-x-mclaren-competition","index":true,"theme":"sungod","title":"McLaren F1 x SunGod Competition. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/sungod-x-mclaren-competition","slugLocaleMapping":{"sungod-x-mclaren-competition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/momentum",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-momentum-momentum",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"121541279","slug":"momentum","index":true,"theme":"sungod","title":"The Momentum Campaign. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/momentum","slugLocaleMapping":{"momentum":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/shop/limited-edition/england-rugby",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-shop__limited-edition__england-rugby-shop__limited-edition__england-rugby",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"96874845","slug":"shop__limited-edition__england-rugby","index":true,"theme":"sungod","title":"SunGod x England Rugby. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/limited-edition/england-rugby","slugLocaleMapping":{"shop__limited-edition__england-rugby":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.path ?? "/:locale()/freeride-world-tour",
    name: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.name ?? "glp-freeride-world-tour-freeride-world-tour",
    meta: {...(_91_46_46_46generic_45landing_45page_933mULUkjA31Meta || {}), ...{"id":"72065085","slug":"freeride-world-tour","index":true,"theme":"sungod","title":"Freeride World Tour","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/freeride-world-tour","slugLocaleMapping":{"freeride-world-tour":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}}},
    alias: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.alias || [],
    redirect: _91_46_46_46generic_45landing_45page_933mULUkjA31Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/miras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-miras",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"miras","id":"UKAC3XfDR3mMbC09po4Srg","index":true,"title":"SunGod Miras™ Sunglasses ","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/miras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/vulcans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans","id":"63537384","index":true,"title":"SunGod Vulcans™ Cycling Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/vulcans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades","id":"63537438","index":true,"title":"SunGod Renegades™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/zephyrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs","id":"122126609","index":true,"title":"SunGod Zephyrs™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/zephyrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/las-vegas-mclaren-zephyrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_mclaren24lasvegas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_mclaren24lasvegas","id":"Tt55dfZeTMObQNukg1HL3A","index":true,"title":"SunGod x McLaren Las Vegas Zephyrs™ Sunglasses","theme":"purple","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/las-vegas-mclaren-zephyrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/airas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-airas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"airas","id":"63537476","index":true,"title":"SunGod Airas™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/airas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras","id":"63537440","index":true,"title":"SunGod Sierras™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/classics3",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics3",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics3","id":"63537436","index":true,"title":"SunGod Classics³ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/classics3"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/ultras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ultras",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"ultras","id":"116109032","index":true,"title":"SunGod Ultras™ - Running Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/ultras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/goggles/snipers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-snipers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"snipers","id":"63537444","index":true,"title":"SunGod Snipers™ Goggles","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/goggles/snipers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/geraint-thomas-gts-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-gts-le_geraintthomas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"gts-le_geraintthomas","id":"116703945","index":true,"title":"SunGod GTs Signature Series™","theme":"silverblue","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/geraint-thomas-gts-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/gts",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-gts",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"gts","id":"116703971","index":true,"title":"SunGod GTs™ - Cycling Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/gts"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/tekkerz",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_tekkerz",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_tekkerz","id":"143334600","index":true,"title":"Tekkerz Limited Edition Vulcanz™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/tekkerz"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/pauline-ferrand-prevot-vulcans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_pauline",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_pauline","id":"fwURvGsXSk2S8dX4ZkrHJQ","index":true,"title":"Pauline Ferrand-Prévot Limited Edition Vulcans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/pauline-ferrand-prevot-vulcans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/vulcans-le_padres-cc",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_padrescc",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_padrescc","id":"APRfWVhiSTC2OMbi8ze7Gw","index":true,"title":"SD Padres City Connect Vulcans™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/vulcans-le_padres-cc"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/vulcans-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_padres","id":"Q8yFPCMkQL2J7_WTX-3NuQ","index":true,"title":"San Diego Padres Vulcans™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/vulcans-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/new-era-vulcans-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_newera",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_newera","id":"GTT7jgxnSFWYtLmJOC7PFw","index":true,"title":"Limited Edition SunGod x New Era Vulcans™ Sunglasses","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-vulcans-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/momentum-vulcans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_momentum",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_momentum","id":"122601864","index":true,"title":"SunGod Momentum Vulcans™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/momentum-vulcans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-vulcans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_mclaren24","id":"GMLCFn_qQhiJOTwepMDu8A","index":true,"title":"McLaren Limited Edition Vulcans™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-vulcans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/ineos-grenadiers-vulcans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcans-le_ineosgrenadiers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_ineosgrenadiers","id":"106462647","index":true,"title":"INEOS Grenadiers Team Edition Vulcans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-grenadiers-vulcans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/tom-pidock-velans-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-velans-le_tompidcock",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-le_tompidcock","id":"122225553","index":true,"title":"Tom Pidcock Signature Series Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/tom-pidock-velans-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/momentum-velans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-velans-le_momentum",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-le_momentum","id":"122604808","index":true,"title":"SunGod Momentum Velans™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/momentum-velans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/ineos-grenadiers-velans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-velans-le_ineosgrenadiers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-le_ineosgrenadiers","id":"106462652","index":true,"title":"INEOS Grenadiers Team Edition Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-grenadiers-velans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/canyon-dhb-sungod",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-velans-le_canyon",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-le_canyon","id":"102391857","index":true,"title":"Canyon DHB SunGod Limited Edition Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/canyon-dhb-sungod"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/velans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-velans",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans","id":"63537411","index":true,"title":"SunGod Velans™ Cycling Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/velans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/signature-series/tom-evans-ultras-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ultras-le_tomevans",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"ultras-le_tomevans","id":"134166787","index":true,"title":"SunGod Tom Evans Signature Series","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/tom-evans-ultras-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/signature-series/hayden-wilde-ultras-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ultras-le_hayden",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"ultras-le_hayden","id":"N9k595xBSkGrX0Ccgm75JA","index":true,"title":"Hayden Wilde Signature Series Ultras™","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/hayden-wilde-ultras-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/wiv-sungod",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-airas-le_wiv",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"airas-le_wiv","id":"121755307","index":true,"title":"WiV SunGod Limited Edition Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/wiv-sungod"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/ineos-grenadiers-airas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-airas-le_ineosgrenadiers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"airas-le_ineosgrenadiers","id":"106412047","index":true,"title":"INEOS Grenadiers Team Edition Airas™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-grenadiers-airas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/zephyrs-le_yankees",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_yankees",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_yankees","id":"V79DhfT2SX6ZBiMhTbVUkQ","index":true,"title":"New York Yankees Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_yankees"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/solstice-zephyrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_solstice",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_solstice","id":"dby2dnJvR8im4N0ZRemV2Q","index":true,"title":"Summer Solstice Limited Edition Zephyrs™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/solstice-zephyrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/lando-norris-zephyrs-silverstone",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_silverstone",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_silverstone","id":"133591413","index":true,"title":" Lando Norris Silverstone Zephyrs™","theme":"ln4","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/lando-norris-zephyrs-silverstone"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/zephyrs-le_redsox",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_redsox",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_redsox","id":"TIaLtUzwRxS6ztl2FeehpQ","index":true,"title":"Boston Red Sox Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_redsox"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/zephyrs-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_padres","id":"V_82_s8VSLSE9cfJd9cCVQ","index":true,"title":"San Diego Padres Zephyrs™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/new-era-zephyrs-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_newera",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_newera","id":"JPnQ6nPtRyy9-sJ0NACMrQ","index":true,"title":"Limited Edition SunGod x New Era Zephyrs™ Sunglasses.","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-zephyrs-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-monaco-zephyrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_monaco23",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_monaco23","id":"110553740","index":true,"title":"McLaren F1 Monaco Triple Crown Zephyrs™️ ","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-monaco-zephyrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-zephyrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_mclaren24","id":"ParremfZTZmkL1OVgxBw1A","index":true,"title":"Limited Edition McLaren Zephyrs Sunglasses.","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-zephyrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/lando-norris-zephyrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_ln4",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_ln4","id":"122221369","index":true,"title":"Limited Edition Lando Norris Sunglasses - Zephyrs™","theme":"ln4","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/lando-norris-zephyrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/zephyrs-le_giants",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_giants",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_giants","id":"IES5C9myThWPw7IekfQKsg","index":true,"title":"San Francisco Giants Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_giants"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/zephyrs-le_dodgers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_dodgers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_dodgers","id":"D4ELoef2Qyit7QyCnapwtQ","index":true,"title":"LA Dodgers Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_dodgers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/zephyrs-le_astros",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_astros",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_astros","id":"KkeunvNqSgaC73xIzCyfdg","index":true,"title":"Houston Astros Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_astros"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/signature-series/airton-cozzolino-zephyrs-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrs-le_airton",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_airton","id":"118500741","index":true,"title":"SunGod Airton Cozzolino Signature Series","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/airton-cozzolino-zephyrs-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/solstice-tokas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tokas-le_solstice",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tokas-le_solstice","id":"YgVshCF5TdmoN4ayxZeg8g","index":true,"title":"Summer Solstice Limited Edition Tokas™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/solstice-tokas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/winners",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tokas-le_mclarenwinners",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tokas-le_mclarenwinners","id":"AIOtQiOuTEisSffaltHEfA","index":true,"title":"SunGod x McLaren Winners' Collection Sunglasses","theme":"dark-chrome","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/mclaren/winners"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/tokas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tokas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tokas","id":"118023005","index":true,"title":"SunGod Tokas™ Sunglasses ","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/tokas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-60th-tokas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tokas-le_mclaren60th",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tokas-le_mclaren60th","id":"172038964","index":true,"title":"McLaren F1 Team 60th Edition Tokas™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-60th-tokas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/sailgp-tempests",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tempests-le_sailgp",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_sailgp","id":"143783828","index":true,"title":"SunGod x Great Britain SailGP Team Collection - Tempests™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sailgp-tempests"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-plus-tempests-polarised-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tempests-le_mclarenplus",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_mclarenplus","id":"119038077","index":true,"title":"SunGod McLaren F1 Team Tempests™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-plus-tempests-polarised-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-extreme-e-tempests",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tempests-le_mclarenmx",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_mclarenmx","id":"122433054","index":true,"title":"SunGod NEOM McLaren Extreme E Tempests™","theme":"mclaren-mx","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-extreme-e-tempests"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/ineos-britannia-tempests",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tempests-le_ineosbritannia",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_ineosbritannia","id":"144993453","index":true,"title":"INEOS Britannia Tempests™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-britannia-tempests"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sunglasses/tempests",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tempests",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests","id":"121923781","index":true,"title":"SunGod Tempests™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/tempests"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/sas-sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_sas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_sas","id":"122607157","index":true,"title":"Surfers Against Sewage - Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sas-sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/sailgp-sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_sailgp",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_sailgp","id":"122520229","index":true,"title":"Great Britain SailGP Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sailgp-sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-fire-sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_mclarenplus",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclarenplus","id":"69566624","index":true,"title":"SunGod McLaren F1 Team Sierras™ - Fire","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-fire-sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-monaco-sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_mclarenmonaco",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclarenmonaco","id":"121508419","index":true,"title":"McLaren Monaco Sierras™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-monaco-sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-monaco-sierras-2024",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_mclaren24monaco",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclaren24monaco","id":"eQJ6CPPmTg6lYrVKJ_PzHg","index":true,"title":"McLaren F1 Team Monaco Limited Edition Sierras™ ","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-monaco-sierras-2024"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-smoke-sierras-2024",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclaren24","id":"Ao8Vq_eSSrKBv-p44y0mxQ","index":true,"title":"SunGod McLaren F1 Team Sierras™ ","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-sierras-2024"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-smoke-sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_mclaren",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclaren","id":"68407946","index":true,"title":"SunGod McLaren F1 Team Sierras™ - Smoke","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-lando-norris",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_ln4",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_ln4","id":"69567203","index":true,"title":"SunGod Lando LN4™ Sierras","theme":"ln4","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-lando-norris"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/signature-series/katie-ormerod",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_katieo",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_katieo","id":"100386006","index":true,"title":"Katie Ormerod Signature Series Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/katie-ormerod"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/ineos-britannia-sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierras-le_ineosbritannia",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_ineosbritannia","id":"144993428","index":true,"title":"Limited Edition INEOS Britannia Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-britannia-sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/renegades-le_yankees",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_yankees",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_yankees","id":"Uxi0ojXDQsOIKaMRc6mRpw","index":true,"title":"New York Yankees Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_yankees"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/signature-series/woodsy-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_woodsy",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_woodsy","id":"69567299","index":true,"title":"Woodsy Signature Series Renegades™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/woodsy-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/williams-f1-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_williams",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_williams","id":"BEbo3Qj_RE2nvWPTpqIvjg","index":true,"title":"SunGod Williams Racing Renegades™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/williams-f1-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/sas-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_sas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_sas","id":"122678597","index":true,"title":"Surfers Against Sewage Collection - Renegades™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sas-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/rfu-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_rfu",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_rfu","id":"69566904","index":true,"title":"SunGod x RFU 150 Renegades™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/rfu-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/renegades-le_redsox",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_redsox",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_redsox","id":"K58L-f9yQ1m-RoWAoxLQEg","index":true,"title":"Boston Red Sox Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_redsox"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/renegades-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_padres","id":"XLMkjPFjRzuYqcsRKZEdJA","index":true,"title":"San Diego Padres Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-fire-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_mclarenplus",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_mclarenplus","id":"69566774","index":true,"title":" SunGod McLaren F1 Team Renegades™ - Fire","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-fire-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-miami-renegades-23",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_mclarenmiami23",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_mclarenmiami23","id":"106393267","index":true,"title":"McLaren Miami Renegades™","theme":"miami","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-miami-renegades-23"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-miami-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_mclarenmiami",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_mclarenmiami","id":"121269455","index":true,"title":"McLaren Miami Renegades™","theme":"miami","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-miami-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-smoke-renegades-2024",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_mclaren24","id":"f7SZtdKdRyKQRKVaaeCVPA","index":true,"title":"SunGod McLaren F1 Team Renegades™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-renegades-2024"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/mclaren-smoke-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_mclaren",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_mclaren","id":"69566773","index":true,"title":"SunGod McLaren F1 Team Renegades™ - Smoke","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/renegades-le_giants",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_giants",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_giants","id":"TAvAPF-lSAesQCd3cFcF-A","index":true,"title":"San Francisco Giants Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_giants"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/ecb-renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_ecb",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_ecb","id":"G-abbyMvRY24tpHIc6w4TA","index":true,"title":"Limited Edition England Cricket Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ecb-renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/renegades-le_dodgers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_dodgers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_dodgers","id":"c7ybxpIaTGGdsqTS-Arsjg","index":true,"title":"LA Dodgers Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_dodgers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/renegades-le_astros",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegades-le_astros",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_astros","id":"edoLzwnzR7aImInmTqNKcQ","index":true,"title":"Houston Astros Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_astros"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/grape-miras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-miras-le_grape",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"miras-le_grape","id":"VnYluIUEQoqFtQMWi9N9LA","index":true,"title":"Limited Edition Grape Miras™","theme":"grape","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/grape-miras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/ecb-miras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-miras-le_ecb",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"miras-le_ecb","id":"eZtsfhqKRoiFSrB3r4ILxg","index":true,"title":"Limited Edition England Cricket Miras™","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ecb-miras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/williams-f1-classics4",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics4-le_williams",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics4-le_williams","id":"LHgBF-mYTX2g1k1Y2IfBNw","index":true,"title":"SunGod Williams Racing Classics³: Official Eyewear Supplier","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/williams-f1-classics4"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/classics4-le_padres-cc",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics4-le_padrescc",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics4-le_padrescc","id":"BB-TFv2zQ0qjQ9CgUYy_Zg","index":true,"title":"SD Padres City Connect Classics³: Official Eyewear Supplier","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/classics4-le_padres-cc"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/classics4-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics4-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics4-le_padres","id":"P2palFF1RuSsB43n8UKDFA","index":true,"title":"San Diego Padres Classics³: Official Eyewear Supplier","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/classics4-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/new-era-classics4-sunglasses",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics4-le_newera",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics4-le_newera","id":"QGcnJrS1TKu-VX3lRQEkqw","index":true,"title":"Limited Edition SunGod x New Era Classics³ Sunglasses","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-classics4-sunglasses"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/courtney-classics",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics4-le_courtney",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics4-le_courtney","id":"cdJrCoPOTviClT9fyARxew","index":true,"title":"SunGod Courtney Dauwalter Classics³","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/courtney-classics"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/sas-classics3",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics3-le_sas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics3-le_sas","id":"122678655","index":true,"title":"Surfers Against Sewage Collection - Classics³","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sas-classics3"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/england-rugby-classics",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics3-le_rfu22",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics3-le_rfu22","id":"102317858","index":true,"title":"SunGod x England Rugby Sunglasses -  Classics³ ","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/england-rugby-classics"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/signature-series/airton-cozzolino",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics3-le_airton",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics3-le_airton","id":"68051738","index":true,"title":"SunGod Airton Cozzolino Signature Series","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/airton-cozzolino"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/goggles/vanguards",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vanguards",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vanguards","id":"63537442","index":true,"title":"SunGod Vanguards™ Goggles","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/goggles/vanguards"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/goggles/ullrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ullrs",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"ullrs","id":"JOxw40i8RnmJfHTVxZooWQ","index":true,"title":"SunGod Ullrs™ Goggles","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/goggles/ullrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/1-percent-for-the-planet-microfibre",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-microfibre-le_1ftp",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"microfibre-le_1ftp","id":"LJPF1v41RqeoEVmKSv5nHA","index":true,"title":"1% for the Planet Charity Microfibre Pouch","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/1-percent-for-the-planet-microfibre"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/microfiber-le_pow23",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-microfibre-le_pow23",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"microfibre-le_pow23","id":"144168445","index":true,"theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/microfiber-le_pow23"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/new-era-9-fifty-cap-small-medium",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-cap-le_newera950sm",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"cap-le_newera950sm","id":"Mj1zoQ7yQvK3hbUkpc0-NA","index":true,"title":"SunGod x New Era Headwear Collection. ","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-fifty-cap-small-medium"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/new-era-9-forty-cap",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-cap-le_newera940",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"cap-le_newera940","id":"amn0s5feTm2KqsINANQfng","index":true,"title":"9FORTY Cap: SunGod x New Era ","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-forty-cap"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/new-era-9-fifty-cap",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-cap-le_newera950",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"cap-le_newera950","id":"T5TdPijlREincoQfEYgl4A","index":true,"title":"SunGod x New Era Headwear Collection. ","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-fifty-cap"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/limited-edition/new-era-9-fifty-cap-medium-large",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-cap-le_newera950ml",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"cap-le_newera950ml","id":"U6dVYl44TQahqj5erOm9pQ","index":true,"title":"9FIFTY Cap: SunGod x New Era","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-fifty-cap-medium-large"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/miras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-miraslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"miraslrk","id":"JjawnbaPSeONY8qWg5Vp0Q","index":true,"title":" SunGod Miras™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/miras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/gts",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-gtslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"gtslrk","id":"V4Z-I3jDTsKnhTX-4gvGYg","index":true,"title":" SunGod GTs™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/gts"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/tempests",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tempestslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempestslrk","id":"121934243","index":true,"title":"SunGod Tempests™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/tempests"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/zephyrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-zephyrslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrslrk","id":"122331765","index":true,"title":" SunGod Zephyrs™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/zephyrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/tokas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-tokaslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tokaslrk","id":"118429377","index":true,"title":" SunGod Tokas™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/tokas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/sierras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sierraslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierraslrk","id":"63537441","index":true,"title":" SunGod Sierras™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/sierras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/classics3",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-classics3lrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics3lrk","id":"63537437","index":true,"title":"SunGod Classics³ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/classics3"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/renegades",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-renegadeslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegadeslrk","id":"63537439","index":true,"title":"SunGod Renegades™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/renegades"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/airas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-airaslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"airaslrk","id":"63537487","index":true,"title":" SunGod Airas™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/airas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/ultras",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ultraslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"ultraslrk","id":"121140949","index":true,"title":"SunGod Ultras™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/ultras"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/vulcans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcanslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcanslrk","id":"63537402","index":true,"title":" SunGod Vulcans™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/vulcans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/velans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-velanslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velanslrk","id":"63537430","index":true,"title":" SunGod Velans™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/velans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/gifts",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-egiftcard",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"egiftcard","id":"94211574","index":true,"theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/gifts"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/lenses/pacebreakers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-pacebreakerslrk",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"pacebreakerslrk","id":"63537536","index":true,"theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/pacebreakers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/parts/airas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-airasck",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"airasck","id":"63537496","index":true,"title":" SunGod Airas™ Conversion Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/airas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/parts/vulcans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vulcansck",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcansck","id":"63537393","index":true,"title":" SunGod Vulcans™ Conversion Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/vulcans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/parts/velans",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-velansck",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velansck","id":"63537421","index":true,"title":" SunGod Velans™ Conversion Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/velans"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/goggle-lens-protector/black",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-gogglelensprotector-gc_black",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"gogglelensprotector-gc_black","id":"FVR1fJ-nR0uzjxU74UakqQ","index":true,"title":"Black Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector/black"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/goggle-lens-protector/blue",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-gogglelensprotector-gc_blue",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"gogglelensprotector-gc_blue","id":"CjCOw7gARDGfjdv_DxoJJA","index":true,"title":"Blue Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector/blue"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/goggle-lens-protector/1-percent-for-the-planet",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-gogglelensprotector-gc_1ftp",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"gogglelensprotector-gc_1ftp","id":"f8XF0U_LTNmpn1WDw5UEWQ","index":true,"title":"1% for the Planet Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector/1-percent-for-the-planet"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/lens-cleaning-kit",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-lenscleankit",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"lenscleankit","id":"Z9GY8-hPRLqgVlj5MBYJrA","index":true,"title":"Lens Cleaning Kit","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/lens-cleaning-kit"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/cases/capsule",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-case",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"case","id":"67973437","index":true,"title":"SunGod Capsule Case","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/cases/capsule"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/goggle-lens-protector",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-gogglelensprotector",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"gogglelensprotector","id":"bvu6ro2qS4ii-_BEftshuw","index":true,"title":"Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/pace-microfibre-kit",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-microfibre-le_pacex2",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"microfibre-le_pacex2","id":"212098380","index":true,"title":"Pace Microfibre Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/pace-microfibre-kit"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/snow-microfibre-kit",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-microfibre-le_snowx2",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"microfibre-le_snowx2","id":"VaipNX3ITSaTgauNuolb1A","index":true,"title":"Snow Microfibre Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/snow-microfibre-kit"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/accessories/lifestyle-microfibre-kit",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-microfibre-le_lifestylex2",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"microfibre-le_lifestylex2","id":"106399845","index":true,"title":"Lifestyle Microfibre Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/lifestyle-microfibre-kit"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/parts/ullrs",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ullrsparts",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"ullrsparts","id":"IdrMUcQjT2WsNl6pLttpag","index":true,"title":" SunGod Ullrs™ Parts Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/ullrs"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/parts/revolts",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-revoltparts",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"revoltparts","id":"63537459","index":true,"title":" Revolts™ Goggles Part Kits","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/revolts"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/parts/vanguards",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-vanguardsparts",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vanguardsparts","id":"63537443","index":true,"title":" SunGod Vanguards™ Parts Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/vanguards"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/parts/snipers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-snipersparts",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"snipersparts","id":"63537450","index":true,"title":" SunGod Snipers™ Parts Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/snipers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-britannia",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-britannia",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_ineosbritannia","slug":"ineos-britannia","isComposite":true,"isCompositeRoot":true,"index":true,"title":"INEOS Britannia Sunglasses Collection","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/ineos-britannia"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-britannia/tempests-le_ineosbritannia",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-britannia-tempests-le_ineosbritannia",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_ineosbritannia","slug":"ineos-britannia","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-britannia/tempests-le_ineosbritannia"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-britannia/sierras-le_ineosbritannia",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-britannia-sierras-le_ineosbritannia",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_ineosbritannia","slug":"ineos-britannia","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-britannia/sierras-le_ineosbritannia"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/surfers-against-sewage",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-surfers-against-sewage",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_sas","slug":"surfers-against-sewage","isComposite":true,"isCompositeRoot":true,"index":true,"title":"Surfers Against Sewage Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/surfers-against-sewage"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/surfers-against-sewage/renegades-le_sas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-surfers-against-sewage-renegades-le_sas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_sas","slug":"surfers-against-sewage","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/surfers-against-sewage/renegades-le_sas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/surfers-against-sewage/classics3-le_sas",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-surfers-against-sewage-classics3-le_sas",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics3-le_sas","slug":"surfers-against-sewage","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/surfers-against-sewage/classics3-le_sas"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_padrescc","slug":"mlb-padres","isComposite":true,"isCompositeRoot":true,"index":true,"title":"San Diego Padres Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-padres/vulcans-le_padrescc",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-padres-vulcans-le_padrescc",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_padrescc","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/vulcans-le_padrescc"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-padres/classics4-le_padrescc",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-padres-classics4-le_padrescc",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics4-le_padrescc","slug":"mlb-padres","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/classics4-le_padrescc"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-padres/vulcans-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-padres-vulcans-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/vulcans-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-padres/classics4-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-padres-classics4-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"classics4-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/classics4-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-padres/zephyrs-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-padres-zephyrs-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/zephyrs-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-padres/renegades-le_padres",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-padres-renegades-le_padres",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/renegades-le_padres"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-astros",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-astros",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_astros","slug":"mlb-astros","isComposite":true,"isCompositeRoot":true,"index":true,"title":"Houston Astros Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-astros"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-astros/zephyrs-le_astros",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-astros-zephyrs-le_astros",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_astros","slug":"mlb-astros","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-astros/zephyrs-le_astros"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-astros/renegades-le_astros",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-astros-renegades-le_astros",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_astros","slug":"mlb-astros","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-astros/renegades-le_astros"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-giants",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-giants",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_giants","slug":"mlb-giants","isComposite":true,"isCompositeRoot":true,"index":true,"title":"San Francisco Giants Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-giants"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-giants/renegades-le_giants",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-giants-renegades-le_giants",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_giants","slug":"mlb-giants","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-giants/renegades-le_giants"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-giants/zephyrs-le_giants",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-giants-zephyrs-le_giants",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_giants","slug":"mlb-giants","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-giants/zephyrs-le_giants"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-dodgers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-dodgers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_dodgers","slug":"mlb-dodgers","isComposite":true,"isCompositeRoot":true,"index":true,"title":"LA Dodgers Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-dodgers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-dodgers/renegades-le_dodgers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-dodgers-renegades-le_dodgers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_dodgers","slug":"mlb-dodgers","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-dodgers/renegades-le_dodgers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-dodgers/zephyrs-le_dodgers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-dodgers-zephyrs-le_dodgers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_dodgers","slug":"mlb-dodgers","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-dodgers/zephyrs-le_dodgers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-redsox",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-redsox",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_redsox","slug":"mlb-redsox","isComposite":true,"isCompositeRoot":true,"index":true,"title":"Boston Red Sox Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-redsox"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-redsox/renegades-le_redsox",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-redsox-renegades-le_redsox",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_redsox","slug":"mlb-redsox","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-redsox/renegades-le_redsox"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-redsox/zephyrs-le_redsox",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-redsox-zephyrs-le_redsox",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_redsox","slug":"mlb-redsox","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-redsox/zephyrs-le_redsox"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-yankees",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-yankees",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_yankees","slug":"mlb-yankees","isComposite":true,"isCompositeRoot":true,"index":true,"title":"New York Yankees Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-yankees"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-yankees/renegades-le_yankees",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-yankees-renegades-le_yankees",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_yankees","slug":"mlb-yankees","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-yankees/renegades-le_yankees"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mlb-yankees/zephyrs-le_yankees",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mlb-yankees-zephyrs-le_yankees",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_yankees","slug":"mlb-yankees","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-yankees/zephyrs-le_yankees"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclaren24monaco","slug":"mclaren","isComposite":true,"isCompositeRoot":true,"index":true,"title":"SunGod x McLaren Formula 1 Team Sunglasses","theme":"mclaren","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mclaren"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/sierras-le_mclaren24monaco",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren-sierras-le_mclaren24monaco",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclaren24monaco","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/sierras-le_mclaren24monaco"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/renegades-le_mclaren24",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren-renegades-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/renegades-le_mclaren24"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/sierras-le_mclaren24",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren-sierras-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"sierras-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/sierras-le_mclaren24"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/vulcans-le_mclaren24",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren-vulcans-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/vulcans-le_mclaren24"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/zephyrs-le_ln4",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren-zephyrs-le_ln4",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_ln4","slug":"mclaren","isComposite":true,"index":false,"theme":"ln4","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/zephyrs-le_ln4"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/renegades-le_mclarenmiami23",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren-renegades-le_mclarenmiami23",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"renegades-le_mclarenmiami23","slug":"mclaren","isComposite":true,"index":false,"theme":"miami","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/renegades-le_mclarenmiami23"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/mclaren/zephyrs-le_mclaren24",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-mclaren-zephyrs-le_mclaren24",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"zephyrs-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/zephyrs-le_mclaren24"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-grenadiers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-grenadiers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"isCompositeRoot":true,"index":true,"title":"INEOS Grenadiers Team Edition Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/ineos-grenadiers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-grenadiers/vulcans-le_ineosgrenadiers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-grenadiers-vulcans-le_ineosgrenadiers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/vulcans-le_ineosgrenadiers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-grenadiers/airas-le_ineosgrenadiers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-grenadiers-airas-le_ineosgrenadiers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"airas-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/airas-le_ineosgrenadiers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-grenadiers/velans-le_ineosgrenadiers",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-grenadiers-velans-le_ineosgrenadiers",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/velans-le_ineosgrenadiers"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-grenadiers/velans-le_tompidcock",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-grenadiers-velans-le_tompidcock",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-le_tompidcock","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/velans-le_tompidcock"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/ineos-grenadiers/velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-ineos-grenadiers-velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sailgp-gbr",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sailgp-gbr",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_sailgp","slug":"sailgp-gbr","isComposite":true,"isCompositeRoot":true,"index":true,"title":"SunGod x Great Britain SailGP Team Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/sailgp-gbr"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/sailgp-gbr/tempests-le_sailgp",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-sailgp-gbr-tempests-le_sailgp",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"tempests-le_sailgp","slug":"sailgp-gbr","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/sailgp-gbr/tempests-le_sailgp"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/momentum",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-momentum",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_momentum","slug":"momentum","isComposite":true,"isCompositeRoot":true,"index":true,"title":"SunGod Momentum Vulcans™ & Velans™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/momentum"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/momentum/vulcans-le_momentum",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-momentum-vulcans-le_momentum",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"vulcans-le_momentum","slug":"momentum","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/momentum/vulcans-le_momentum"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: indexVFGh14RhQYMeta?.path ?? "/:locale()/shop/momentum/velans-le_momentum",
    name: indexVFGh14RhQYMeta?.name ?? "pdp-momentum-velans-le_momentum",
    meta: {...(indexVFGh14RhQYMeta || {}), ...{"sku":"velans-le_momentum","slug":"momentum","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/momentum/velans-le_momentum"}},
    alias: indexVFGh14RhQYMeta?.alias || [],
    redirect: indexVFGh14RhQYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    path: _91slug_93nA585D1AOqMeta?.path ?? "/:locale()/run-with-courtney",
    name: _91slug_93nA585D1AOqMeta?.name ?? "typeform-run-with-courtney",
    meta: {...(_91slug_93nA585D1AOqMeta || {}), ...{"slug":"run-with-courtney","index":false,"formId":"w53w80ds","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/run-with-courtney"}},
    alias: _91slug_93nA585D1AOqMeta?.alias || [],
    redirect: _91slug_93nA585D1AOqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93nA585D1AOqMeta?.path ?? "/:locale()/co-brand-form",
    name: _91slug_93nA585D1AOqMeta?.name ?? "typeform-co-brand-form",
    meta: {...(_91slug_93nA585D1AOqMeta || {}), ...{"slug":"co-brand-form","index":false,"formId":"bbMbHzrB","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/co-brand-form"}},
    alias: _91slug_93nA585D1AOqMeta?.alias || [],
    redirect: _91slug_93nA585D1AOqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93nA585D1AOqMeta?.path ?? "/:locale()/prescription-lenses",
    name: _91slug_93nA585D1AOqMeta?.name ?? "typeform-prescription-lenses",
    meta: {...(_91slug_93nA585D1AOqMeta || {}), ...{"slug":"prescription-lenses","index":false,"formId":"hzs484kM","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/prescription-lenses"}},
    alias: _91slug_93nA585D1AOqMeta?.alias || [],
    redirect: _91slug_93nA585D1AOqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93nA585D1AOqMeta?.path ?? "/:locale()/fwq-fjt-athletes",
    name: _91slug_93nA585D1AOqMeta?.name ?? "typeform-fwq-fjt-athletes",
    meta: {...(_91slug_93nA585D1AOqMeta || {}), ...{"slug":"fwq-fjt-athletes","index":false,"formId":"eMKDck","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/fwq-fjt-athletes"}},
    alias: _91slug_93nA585D1AOqMeta?.alias || [],
    redirect: _91slug_93nA585D1AOqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93nA585D1AOqMeta?.path ?? "/:locale()/pro-deal",
    name: _91slug_93nA585D1AOqMeta?.name ?? "typeform-pro-deal",
    meta: {...(_91slug_93nA585D1AOqMeta || {}), ...{"slug":"pro-deal","index":false,"formId":"rxXr5A","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/pro-deal"}},
    alias: _91slug_93nA585D1AOqMeta?.alias || [],
    redirect: _91slug_93nA585D1AOqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93nA585D1AOqMeta?.path ?? "/:locale()/return-authorisation",
    name: _91slug_93nA585D1AOqMeta?.name ?? "typeform-return-authorisation",
    meta: {...(_91slug_93nA585D1AOqMeta || {}), ...{"slug":"return-authorisation","index":false,"formId":"p2nSC6","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/return-authorisation"}},
    alias: _91slug_93nA585D1AOqMeta?.alias || [],
    redirect: _91slug_93nA585D1AOqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/product-guides",
    name: index6qPaNkZD7LMeta?.name ?? "ec-product-guides",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"SynDO1jpQcWvRJO8RvO5RA","slug":"product-guides","index":true,"theme":"explore","title":"Product Guides ","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/product-guides"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/sports",
    name: index6qPaNkZD7LMeta?.name ?? "ec-sports",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"W1G_AGtoQ2WXa5xgr8lfVQ","slug":"sports","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/sports"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/running",
    name: index6qPaNkZD7LMeta?.name ?? "ec-running",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"XMSuBQddQTmHPFNY3AMtIA","slug":"running","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/running"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/cycling",
    name: index6qPaNkZD7LMeta?.name ?? "ec-cycling",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"fg9D88DoRIWBX9oexQZzxA","slug":"cycling","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/cycling"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/snowsports",
    name: index6qPaNkZD7LMeta?.name ?? "ec-snowsports",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"DnvidYYgRGaOxFxzt-DquQ","slug":"snowsports","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/snowsports"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/athlete-stories",
    name: index6qPaNkZD7LMeta?.name ?? "ec-athlete-stories",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"MywN3iPMSiyplVGFs5sW4A","slug":"athlete-stories","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/athlete-stories"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/pro-team",
    name: index6qPaNkZD7LMeta?.name ?? "ec-pro-team",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"80143887","slug":"pro-team","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/pro-team"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/video",
    name: index6qPaNkZD7LMeta?.name ?? "ec-video",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"80143904","slug":"video","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/video"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/stories",
    name: index6qPaNkZD7LMeta?.name ?? "ec-stories",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"80143878","slug":"stories","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/stories"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: index6qPaNkZD7LMeta?.path ?? "/:locale()/explore/news",
    name: index6qPaNkZD7LMeta?.name ?? "ec-news",
    meta: {...(index6qPaNkZD7LMeta || {}), ...{"categoryId":"80143880","slug":"news","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/news"}},
    alias: index6qPaNkZD7LMeta?.alias || [],
    redirect: index6qPaNkZD7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: indexylUyG9oox5Meta?.path ?? "/:locale()/shop/lenses",
    name: indexylUyG9oox5Meta?.name ?? "legacy-collection-shop__lenses",
    meta: {...(indexylUyG9oox5Meta || {}), ...{"index":true,"title":"Replacement Lenses & Parts","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/lenses"}},
    alias: indexylUyG9oox5Meta?.alias || [],
    redirect: indexylUyG9oox5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: indexylUyG9oox5Meta?.path ?? "/:locale()/shop/parts",
    name: indexylUyG9oox5Meta?.name ?? "legacy-collection-shop__parts",
    meta: {...(indexylUyG9oox5Meta || {}), ...{"index":true,"title":"Goggle Parts","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/parts"}},
    alias: indexylUyG9oox5Meta?.alias || [],
    redirect: indexylUyG9oox5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: indexylUyG9oox5Meta?.path ?? "/:locale()/shop/cases",
    name: indexylUyG9oox5Meta?.name ?? "legacy-collection-shop__cases",
    meta: {...(indexylUyG9oox5Meta || {}), ...{"index":true,"title":"Cases","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/cases"}},
    alias: indexylUyG9oox5Meta?.alias || [],
    redirect: indexylUyG9oox5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue").then(m => m.default || m)
  },
  {
    path: indexylUyG9oox5Meta?.path ?? "/:locale()/shop/parts/pace",
    name: indexylUyG9oox5Meta?.name ?? "legacy-collection-shop__parts__pace",
    meta: {...(indexylUyG9oox5Meta || {}), ...{"index":true,"title":"Pace Series™ Parts","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/parts/pace"}},
    alias: indexylUyG9oox5Meta?.alias || [],
    redirect: indexylUyG9oox5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue").then(m => m.default || m)
  }
]