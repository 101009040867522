import { mediaFragment, responsiveMediaFragment } from './mediaFragments.gql'
import {
  genericStructuredTextFragment,
  inlineCTARecordFragment,
  titleBlockFragment,
  singleImageRecordFragment,
  customThemeFragment,
} from './structuredText.gql'

export const seoFragment = `meta: _seoMetaTags {
    attributes
    content
    tag
  }`

export const ctaFragment = `
  ctaLink
  ctaCopy
  showCta
`

export const internalLinkFragment = `
  ... on HomepageRecord {
    id
    _modelApiKey
  }
  ... on FilterPageRecord {
    id
    slug
  }
  ... on BaseProductRecord {
    id
    slug: url
  }
  ... on ArticleCategoryRecord {
    id
    slug
    _modelApiKey
  }
  ... on ArticleRecord {
    id
    slug
    _modelApiKey
    category {
      slug
    }
  }
  ... on CareersPageRecord {
    id
    slug
  }
  ... on CompetitionsPageRecord {
    id
    slug
  }
  ... on CompositePdpRecord {
    id
    slug
    _modelApiKey
  }
  ... on ExploreLandingRecord {
    id
    slug
  }
  ... on GiftingPageRecord {
    id
    slug
  }
  ... on GuaranteeRegistrationPageRecord {
    id
    slug
  }
  ... on LandingPageRecord {
    id
    slug
  }
  ... on PartnerPageRecord {
    id
    slug
  }
  ... on SustainabilityPageRecord {
    id
    slug
  }
  ... on SupportPageRecord {
    id
    slug
  }
  ... on LegacyCollectionRecord {
    slug
    id
  }
`

export const splitTestVariantFragment = `
  splitTestVariant {
    id
    variantId
    splitTestKey
  }
`

export const typeFragment = 'type: _modelApiKey'
export const displayFragment = `
  visibility
  skuPattern
  disableBottomMargin
  ${splitTestVariantFragment}
`

export const headingFragment = `
  ${typeFragment}
  ${displayFragment}
  title
  titleGradient
  titleSize
  titleTag
  subtitle
  subtitleTag
  subtitleGradient
  fullWidthSubtitle
  copy
  copySize
  copyTag
  fullWidthCopy
  showCta
  ctaCopy
  ctaLink
  centerContent
`

export const headingRecord = `
  ... on HeadingRecord {
    id
    ${headingFragment}
  }`

export const standfirstRecord = `
  ... on StandfirstRecord {
    id
    ${typeFragment}
    ${displayFragment}
    title
    titleTag
    largeTitle
    subtitle
    fullWidth
  }`

export const dividerRecord = `
  ... on DividerRecord {
    id
    ${typeFragment}
    ${displayFragment}
  }`

export const bannerFragment = `
  ${typeFragment}
  ${displayFragment}
  ${responsiveMediaFragment()}
  ${responsiveMediaFragment('mobileImage')}
  overlayOpacity
  mobileOverlayOpacity
  ${ctaFragment}
  displayTitle
  titleSize
  titleTag
  copy
  useRichText
  richTextCopy
  halfWidthCopy
  fullWidthCopy
  inlineCopy
  primaryColour
  headingGradient
  fullWidth
  ctaGradient
  bannerHeightAuto
  matchHeightToContent
  minHeight
  bottomPadding
  disableParallax
  contentAlignment
  hidden
  displayOnlyWithUrlParam
  features {
    id
    icon {
      url
    }
    text
  }
`

export const bannerRecord = `
  ... on BannerRecord {
    id
    ${bannerFragment}
  }`

export const customisedProductFragment = `
  _modelApiKey
  sku
  title
  subTitle
  frameSwatchOverride
  lensSwatchOverride
  swatchIconOverride {
    url(imgixParams: {w: "50", h: "50"})
  }
  lensPart
  limitedEdition
  partsLimitedEdition
  stockManagedPart
`

export const stackFragment = `
  ${typeFragment}
  ${mediaFragment()}
  ${ctaFragment}
  link
  title
  titleSize
  titleTag
  subtitle
  copy
  copySize
  ctaType
  ctaStyle
  aspectRatio
  fullHeight
`

export const productCardFragment = `
  ${typeFragment}
  showTag
  tagCopy
  imageNumber
  useBaseProduct
  showSwatchSelector
  showReviewStars
  isPrescriptionProducts
  titleTag
  products {
    ${typeFragment}
    sku
    title
    subTitle
    frameSwatchOverride
    lensSwatchOverride
    swatchIconOverride {
      url(imgixParams: {w: "50", h: "50"})
    }
    lensPart
    limitedEdition
    ${mediaFragment('logo', '{ w: "200", h: "100", fit: fill }')}
    stockManagedPart
    theme
    baseProduct {
      sku
      swatchIconOverride {
        url(imgixParams: {w: "50", h: "50"})
      }
      theme
      promoMessaging {
        enabled
        tagText
        theme {
          themeType
        }
      }
    }
  }
  baseProduct {
    ${typeFragment}
    sku
  }
  onGiftingPage
  showGiftCard
  seeMoreLinkOverride
`

export const sustainabilityBlockFragment = `
  id
  customClass
  backgroundColour
  ${mediaFragment('desktopBackground', '{ w: "1400", h: "1300", fit: clip }')}
  ${mediaFragment('mobileBackground', '{ w: "1000", h: "900", fit: clip }')}
  ${mediaFragment('icon', '{ w: "320", h: "200", fit: clip }')}
  iconPosition
  heading {
    ... on HighlighterHeadingRecord {
      __typename
      _modelApiKey
      id
      highlightType
      heading {
        value
      }
    }
  }
  modalContent {
    slug
  }
  showSubheading
  subheading {
    blocks
    value
  }
  subheadingColour
`

export const dataCaptureBlock = `
  id
  ${typeFragment}
  title
  intro
  ctaCopy
  showMailingListSubscribeCheckbox
  requireMailingListSubscription
  mailingListCopy
  verifyCopy
  successMessage {
    value
    blocks {
      id
      __typename
      copy
      style
      target
    }
  }
  successReplacesForm
  terms {
    value
    links {
      __typename
      slug
      _modelApiKey
      id
    }
  }
  ${responsiveMediaFragment('media', '{ w: "1000", h: "900", fit: clip }')}
  mediaVisibility
  borderVisibility
  config {
    id
    slug
    requireEmailVerification
  }
`

export const modalSidebarFragment = `
  ... on DialogRecord {
    ${typeFragment}
    id
    slug
  }
  ... on SidebarPageRecord {
    ${typeFragment}
    id
    slug
  }
`

export const linkBlock = `
  internalLink {
    ${internalLinkFragment}
  }
  internalLinkQueryParams
  modalSidebar {
    ${modalSidebarFragment}
  }
`

export const videoRecord = `
  ... on VideoRecord {
    ${typeFragment}
    ${displayFragment}
    id
    loop
    muted
    autoPlay
    previewStartTime
    fullWidth
    video {
      height
      width
      title
      thumbhash
      video {
        muxPlaybackId
      }
    }
  }`

export const cardFragment = `
  ${typeFragment}
  id
  content {
    ... on TitleBlockRecord {
      ${typeFragment}
      id
      ${titleBlockFragment}
    }
    ... on ButtonGroupRecord {
      id
      ${typeFragment}
      buttons {
        id
        text
        style
        link {
          ${linkBlock}
        }
      }
    }
    ... on DescriptionRecord {
      id
      ${typeFragment}
      description
    }
    ... on IconRecord {
      ${typeFragment}
      icon
    }
    ... on SingleImageRecord {
      ${typeFragment}
      ${singleImageRecordFragment}
    }
  }
  link {
    ${linkBlock}
  }
  ${responsiveMediaFragment('image', '{ w: "1200", h: "1200" }')}
  ${responsiveMediaFragment('mobileImage', '{ w: "720", h: "720" }')}
  alignment
  bgColour {
    hex
  }
  colour
  iconButton
  justification
  linkText
  scrim
  shadow
  subtitle
  subtitleStyle
  linkedTheme {
    ${customThemeFragment}
  }
  trackingId
`

export const mediaHeaderBlock = `
  id
  ${typeFragment}
  horizontalAlignment
  desktopMinHeight
  mobileMinHeight
  foregroundImagesDesktopPosition
  content {
    ${genericStructuredTextFragment}
  }
  ${responsiveMediaFragment('backgroundImage', '{ w: "2000", h: "1000" }')}
  ${responsiveMediaFragment('desktopImage1', '{ w: "600", h: "600" }')}
  desktopImage1Link {
    ${linkBlock}
  }
  ${responsiveMediaFragment('desktopImage2', '{ w: "600", h: "600" }')}
  desktopImage2Link {
    ${linkBlock}
  }
  ${responsiveMediaFragment('mobileImage', '{ w: "600", h: "600" }')}
  mobileImageLink {
    ${linkBlock}
  }
  backgroundVideo {
    ${videoRecord}
  }
  backgroundMediaOverlay
  backgroundMediaLink {
    ${linkBlock}
  }
  trackingId
  theme {
    ${customThemeFragment}
  }
`

export const emailSignupBlock = `
  id
  ${typeFragment}
  ${responsiveMediaFragment()}
  headingText
  headingSize
  headingStyle
  title {
    ${titleBlockFragment}
  }
  body {
    value
    blocks
    links
  }
  formEmailPlaceholder
  formCtaText
  formOptIn
  formOptInText
  formSuccess {
    value
    blocks {
      ${inlineCTARecordFragment}
    }
    links
  }
  stylingBackgroundColours {
    colour {
      hex
    }
  }
  stylingBackgroundGradientAngle
  stylingLayout
  stylingTextStyle
  stylingInputStyle
  stylingButton
  stylingButtonStyle
  trackingId
`

export const marketingRowBlock = `
  id
  ${typeFragment}
  layout
  trackingId
  aspectRatio
  cards {
    ${cardFragment}
  }
`

export const impactCarouselBlock = `
  id
  ${typeFragment}
  trackingId
  layout
  purpose
  content: title {
    ${genericStructuredTextFragment}
  }
  ${responsiveMediaFragment('backgroundImage', '{ w: "800", h: "800" }')}
  exploreCategories {
    id
  }
  cards {
    id
    ${typeFragment}
    trackingId
    title
    description
    ${responsiveMediaFragment('media', '{ w: "500", h: "500" }')}
    link {
      ${linkBlock}
    }
  }
`

export const InstagramShopBlock = `
  id
  ${typeFragment}
  trackingId
  posts
  rows
  content {
    ${genericStructuredTextFragment}
  }
  instashopProducts {
    sku
  }
`

export const dataCaptureBlockRecord = `
  ... on DataCaptureBlockRecord {
    ${dataCaptureBlock}
  }
`

export const mediaHeaderRecord = `
  ... on MediaHeaderRecord {
    ${mediaHeaderBlock}
  }
`
export const emailSignupRecord = `
  ... on EmailSignupRecord {
    ${emailSignupBlock}
  }
`

export const marketingRowRecord = `
  ... on MarketingRowRecord {
    ${marketingRowBlock}
  }
`
export const impactCarouselRecord = `
  ... on ImpactCarouselRecord {
    ${impactCarouselBlock}
  }
`

export const instagramShopRecord = `
  ... on InstagramShopRecord {
    ${InstagramShopBlock}
  }
`

export const multiColumnRecord = `
  ... on MultiColumnRecord {
    id
    ${typeFragment}
    ${displayFragment}
    columns {
      ... on BlankRecord {
        id
        ${typeFragment}
      }
      ... on ProductCardRecord {
        id
        ${productCardFragment}
      }
      ... on StackRecord {
        id
        ${stackFragment}
      }
      ... on BannerCardRecord {
        id
        ${typeFragment}
        ${mediaFragment()}
        displayTitle
        titleSize
        primaryColour
        copy
      }
      ... on ContentBlockRecord {
        id
        ${typeFragment}
        model {
          ${typeFragment}
          ${sustainabilityBlockFragment}
        }
      }
    }
  }`

export const richTextRecord = `
  ... on RichTextRecord {
    ${typeFragment}
    id
    content
    matchPaddingToBanner
    ${ctaFragment}
    ctaGradient
    ${displayFragment}
}`

export const displayPriceOverrideFragment = `
  percentageOff
`

export const carouselRecord = `
  ... on CarouselRecord {
    ${typeFragment}
    id
    content {
      ${genericStructuredTextFragment}
    }
    globalPriceOverride {
      ${displayPriceOverrideFragment}
    }
    trackingId
    items {
      ... on CarouselItemRecord {
        ${typeFragment}
        id
        title
        titleHtmlTag
        subtitle
        target
        overlayOpacity
        ${mediaFragment()}
      }
      ... on ProductCardRecord {
        ${productCardFragment}
      }
    }
  }`

export const centeredCarouselRecord = `
  ... on CenteredCarouselRecord {
    ${typeFragment}
    id
    background
    trackingId
    titleBlock {
      ${titleBlockFragment}
    }
    items {
      ${typeFragment}
      id
      title
      cta
      trackingId
      internalLink {
        ${internalLinkFragment}
      }
      ${mediaFragment()}
      imageColours: image {
        colors {
          hex
        }
      }
      backgroundColours
      customColours {
        ${typeFragment}
        colour {
          hex
        }
      }
    }
  }`

export const styledBannerFragment = `
  ${typeFragment}
  id
  backgroundColour
  condensedView
  style
  ${responsiveMediaFragment()}
  ${responsiveMediaFragment('mobileImage')}
  content {
    blocks
    value
  }
  textColour
`

export const styledBannerRecord = `
  ... on StyledBannerRecord {
    ${styledBannerFragment}
  }`

export const sustainabilityBlockRecord = `
  ... on SustainabilityBlockRecord {
    ${typeFragment}
    id
    ${sustainabilityBlockFragment}
  }
`

export const formBlockRecord = `
  ... on FormBlockRecord {
    ${typeFragment}
    id
    formId
    disableBottomMargin
  }
`

export const faqBlockRecord = `
  ... on FaqBlockRecord {
    ${typeFragment}
    id
    uid
    title
    disableBottomMargin
    footerCopy
    articles {
      id
      title
      bodyCopy
    }
  }
`

export const anchorButtonRowRecord = `
  ... on AnchorButtonRowRecord {
    ${typeFragment}
    id
    anchorButtonContainer {
      ... on AnchorButtonRecord {
        id
        buttonText
        sectionId
      }
      ${inlineCTARecordFragment}
    }
  }
`

export const competitionBlockRecord = `
  ... on CompetitionBlockRecord {
    ${typeFragment}
    id
    competition {
      id
      competitionName
      competitionMode
      slug
      competitionId
      closingDatetime
      inIframe
    }
  }
`

export const gridGalleryBlockRecord = `
  ... on GalleryGridRecord {
    ${typeFragment}
    id
    title {
      ${titleBlockFragment}
    }
    items {
      id
      link {
        ${linkBlock}
      }
      text {
        ${titleBlockFragment}
      }
      ${responsiveMediaFragment('image', '{ w: "600", h: "600" }')}
      ${responsiveMediaFragment('mobileImage', '{ w: "600", h: "600" }')}
      aspectRatio
      backgroundVideo {
        ${videoRecord}
      }
      backgroundMediaOverlay
      trackingId
    }
    trackingId
    lazyLoadImages
  }
`

export const rowsFragment = `{
    ${headingRecord}
    ${dividerRecord}
    ${bannerRecord}
    ${videoRecord}
    ${standfirstRecord}
    ${multiColumnRecord}
    ${richTextRecord}
    ${carouselRecord}
    ${styledBannerRecord}
    ${sustainabilityBlockRecord}
    ${formBlockRecord}
    ${faqBlockRecord}
    ${anchorButtonRowRecord}
    ${competitionBlockRecord}
    ${dataCaptureBlockRecord}
    ${centeredCarouselRecord}
    ${mediaHeaderRecord}
    ${gridGalleryBlockRecord}
    ${emailSignupRecord}
    ${marketingRowRecord}
    ${instagramShopRecord}
    ${impactCarouselRecord}
  }
`

export const builderFragment = `
  pageTheme
  rows ${rowsFragment}
  title
  ${seoFragment}
`

export const commerceTagFragment = `
  ... on CommerceTagRecord {
    title
    value
  }
`

export const productCategoryFragment = `
  __typename
  id
  isComingSoon
  contentFilter {
    ${commerceTagFragment}
  }
  optionContent {
    value
    links {
      __typename
      ${styledBannerRecord}
    }
  }
  heading
`

export const localeFragment = 'fallbackLocales: en, locale: $locale'
