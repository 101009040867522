import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU,
  getSKUWithoutPrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Idarkgrey_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Inavy_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Iblack_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mpink_{nnn}.png'
    },
    Mred: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mred_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mwhite_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mstone_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/vef_Mslateblue_{nnn}.png'
    },
    Ipink: {
      pathTemplate: 'gs://sg360/220520/Velans/vef/vef_Mpink_{nnn}.png'
    },
  }
}

export const bottom = {
  id: 'bottom',
  name: 'Bottom',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Idarkgrey_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Inavy_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Iblack_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mpink_{nnn}.png'
    },
    Mred: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mred_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mwhite_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mstone_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/veb_Mslateblue_{nnn}.png'
    },
    Ipink: {
      pathTemplate: 'gs://sg360/220520/Velans/veb/veb_Mpink_{nnn}.png'
    }
  }
}

export const nose = {
  id: 'nose',
  name: 'Nose',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Idarkgrey_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Inavy_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Iblack_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mmint_{nnn}.png'
    },
    Imint: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mpink_{nnn}.png'
    },
    Ipink: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mpink_{nnn}.png'
    },
    Mred: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mred_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mwhite_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mstone_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/ven/ven_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/ven_Mslateblue_{nnn}.png'
    }
  }
}

export const earsocks = {
  id: 'earsocks',
  name: 'Earsocks',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Rblack: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rblack_{nnn}.png'
    },
    Rblue: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rblue_{nnn}.png'
    },
    Rgold: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rgold_{nnn}.png'
    },
    Rgrey: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rgrey_{nnn}.png'
    },
    Rnavy: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rnavy_{nnn}.png'
    },
    Rstone: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rstone_{nnn}.png'
    },
    Rred: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rred_{nnn}.png'
    },
    Rmint: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rmint_{nnn}.png'
    },
    Rwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/vees/vees_Rwhite_{nnn}.png'
    },
    Rslateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/vees_Mslateblue_{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bgold_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bgunmetal_{nnn}.png'
    },
    Bpurple: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bpurple_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bred_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Brosegold_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220520/Velans/vei/vei_Bwhite_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    clearTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_clear_{nnn}.png'
    },
    fireTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_fire_{nnn}.png'
    },
    goldTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_gold_{nnn}.png'
    },
    greenTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_green_{nnn}.png'
    },
    hvblueTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_hvblue_{nnn}.png'
    },
    IRhvblueTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_IRhvblue_{nnn}.png'
    },
    IRsmokeTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_IRsmoke_{nnn}.png'
    },
    purpleTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_purple_{nnn}.png'
    },
    silverblueTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_silverblue_{nnn}.png'
    },
    smokeTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_smoke_{nnn}.png'
    },
    roseTF: {
      pathTemplate: 'gs://sg360/240815/Ve_tfrose-{nnn}.png'
    },
    chromeTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/TF/vele_TF_chrome_{nnn}.png'
    },
    clearFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_clear_{nnn}.png'
    },
    fireFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_fire_{nnn}.png'
    },
    goldFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_gold_{nnn}.png'
    },
    greenFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_green_{nnn}.png'
    },
    hvblueFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_hvblue_{nnn}.png'
    },
    IRhvblueFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_IRhvblue_{nnn}.png'
    },
    IRsmokeFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_IRsmoke_{nnn}.png'
    },
    purpleFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_purple_{nnn}.png'
    },
    silverblueFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_silverblue_{nnn}.png'
    },
    smokeFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_smoke_{nnn}.png'
    },
    roseFF: {
      pathTemplate: 'gs://sg360/240815/Ve_ffrose-{nnn}.png'
    },
    chromeFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele/FF/vele_FF_chrome_{nnn}.png'
    },
    chrome4FF: {
      pathTemplate: 'gs://sg360/240815/vele_FF_chrome4_{nnn}.png'
    },
    chrome4TF: {
      pathTemplate: 'gs://sg360/240815/vele_TF_chrome4_{nnn}.png'
    },
    waterFF: {
      pathTemplate: 'gs://sg360/240815/vele_8water-FF-{nnn}.png'
    },
    waterTF: {
      pathTemplate: 'gs://sg360/240815/vele_8water-TF-{nnn}.png'
    }
  }
}

export const iris = {
  id: 'iris',
  name: 'Iris Mask',
  imgproxySettings,
  mobileImgproxySettings,
  isIrisMask: true,
  options: {
    none: {
      disabled: true
    },
    FF: {
      pathTemplate: 'gs://sg360/220520/Velans/Iris Mask/FF/vle_Iris Mask_FF_{nnn}.png'
    },
    TF: {
      pathTemplate: 'gs://sg360/220520/Velans/Iris Mask/TF/vle_Iris Mask_TF_{nnn}.png'
    }
  }
}

export const reflection = {
  id: 'reflections',
  isReflection: true,
  name: 'Reflections',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    r5pcTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele Cloud Overlay/TF/5/vele_TF_Cloud Overlay 5_{nnn}.png'
    },
    r10pcTF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele Cloud Overlay/TF/10/vele_TF_Cloud Overlay 10_{nnn}.png'
    },
    r5pcFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele Cloud Overlay/FF/5/vele_FF_Cloud Overlay 5_{nnn}.png'
    },
    r10pcFF: {
      pathTemplate: 'gs://sg360/220520/Velans/vele Cloud Overlay/FF/10/vele_FF_Cloud Overlay 10_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220520/Velans/velg/velg_N_{nnn}.png'
    }
  }
}

function resolveOptions (customisedProduct) {
  const design = customisedProduct.state.choices

  return {
    frame: getSKUWithoutPrefix(design.vef),
    bottom: getSKUWithoutPrefix(design.sb) || 'none',
    nose: getSKUWithoutPrefix(design.sn) || 'none',
    icons: getSKUWithoutPrefix(design.i),
    engraving: 'eN',
    earsocks: getSKUWithoutPrefix(design.es),
    lens: getSKUWithoutPrefix(customisedProduct.lensChoice.skuWithoutLensTech) + customisedProduct.frameType.toUpperCase(),
    iris: customisedProduct.frameType.toUpperCase(),
    reflections: 'r5pc' + customisedProduct.frameType.toUpperCase()
  }
}

function resolveSecondaryOptions (customisedProduct) {
  const design = customisedProduct.state.secondaryChoices
  return {
    bottom: getSKUWithoutPrefix(design.sb) || 'none',
    nose: getSKUWithoutPrefix(design.sn) || 'none'
  }
}

export default {
  supports360: false,
  layers: [
    background,
    frame,
    earsocks,
    bottom,
    nose,
    icons,
    lenses,
    iris,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveSecondaryOptions,
  resolveOptionsFromSKU
}